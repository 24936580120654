import { useContext } from "react"
import styled from 'styled-components'
import { DevModeContext } from './dev-mode-context'

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #BFD2E4;
  font-size: small;
`

const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
  background: #727272;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: #004A97;
    transform: translate(0, -50%);
  }
`

const Input = styled.input`
  display: none;

  &:checked + ${Switch} {
    background: #259400;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`

export const ToggleSwitch = () => {
  const { enabled, showContent, flipDisplayDebugInfo } = useContext(DevModeContext)

  const handleChange = () => {
    flipDisplayDebugInfo()
  }


  return (
    <div>
      {enabled &&
        <Label>
          <span>Show debug info</span>
          <Input checked={showContent} type="checkbox" onChange={handleChange} />
          <Switch />
        </Label>
      }
    </div >
  )
}