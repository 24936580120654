import { CoreDocument } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { Section, SpacedBox, Button, useOverlay, Panel, Avatar, Module, Text, LinkButton, Spinner, DebugInfo } from '../../components'
import { useCommandResult, useCore } from '../../core'
import Settings from '../settings'
import { UserAccount } from './types'
import MyPersonas from './my-personas'
import MyProfileForm from './my-profile-form'
import CurrentTenant from './current-tenant'

interface MyProfileProps {
  onBack: () => void
}

const MyProfile: FC<MyProfileProps> = ({ onBack }) => {
  const { logout, session } = useCore()
  const { open: openTray } = useOverlay("tray")

  const userAccount = useCommandResult<CoreDocument<UserAccount>>("navarik.id.user.account.get")
  if (!userAccount) {
    return <Spinner />
  }

  const editProfile = () => openTray(
    <MyProfileForm body={userAccount.body} onSuccess={onBack} onCancel={onBack} />,
    { size: "small" },
    onBack
  )

  const createNewTenant = () => openTray(
    <Settings.TenantForm onClose={onBack} />,
    { size: "small" },
    onBack
  )

  return (
    <Section>
      <Section.Header>
        <Panel>
          <SpacedBox>
            <Avatar size="lg" url={userAccount.body.avatar} />

            <Module>
              <Text>{userAccount.body.displayName}</Text>
              <Text color="light">{userAccount.body.email}</Text>

              <CurrentTenant />
              <LinkButton onClick={editProfile}>edit profile</LinkButton>
            </Module>
          </SpacedBox>
          <SpacedBox>
            <DebugInfo>{"Persona ID: " + session.persona}</DebugInfo>
          </SpacedBox>
        </Panel>
      </Section.Header>

      <MyPersonas />

      <Section.Footer>
        <SpacedBox align="spread">
          <Button onClick={createNewTenant} color="primary" icon="add" title="Add Account" />
          <Button onClick={logout} color="danger" icon="exit" title="Log Out" />
        </SpacedBox>
      </Section.Footer>
    </Section>
  )
}

export default MyProfile
