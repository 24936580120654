import { FC } from 'react'
import { Form, Button, SpacedBox } from "../../components"
import { useCore } from '../../core'

interface MyProfileFormProps {
  body: object
  onSuccess: () => any
  onCancel: () => any
}

const MyProfileForm: FC<MyProfileFormProps> = ({ body, onSuccess, onCancel }) => {
  const { core } = useCore()

  const save = async (data) => {
    await core.command("navarik.id.user.account.update", data)
    onSuccess()
  }

  return (
    <Form onSubmit={save} data={{ ...body }}>
      <Form.Field id="firstName" >
        <Form.Label>First Name</Form.Label>
        <Form.Input type="text" field="firstName" />
        <Form.Validators.Required field="firstName">This field cannot be blank</Form.Validators.Required>
      </Form.Field>

      <Form.Field id="lastName" >
        <Form.Label>Last Name</Form.Label>
        <Form.Input type="text" field="lastName" />
        <Form.Validators.Required field="lastName">This field cannot be blank</Form.Validators.Required>
      </Form.Field>

      <Form.Field id="displayName" >
        <Form.Label>Display Name</Form.Label>
        <Form.Input type="text" field="displayName" />
        <Form.Validators.Required field="displayName">This field cannot be blank</Form.Validators.Required>
      </Form.Field>

      <Form.Footer>
        <SpacedBox align="spread">
          <Button type="submit" title="Save Changes" icon="save" />
          <Button color="secondary" title="Cancel" icon="cancel" onClick={onCancel} />
        </SpacedBox>
      </Form.Footer>
    </Form>
  )
}

export default MyProfileForm
