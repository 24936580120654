import { FC } from 'react'
import { Form, SpacedBox, Title, Button, Text, Module, Toolbar } from "../../../components"
import { useCore } from '../../../core'
import ReferenceSelector from '../../../shared/reference/reference-selector'
import { Workspace } from "../types"

interface WorkspaceFormProps {
  id?: string
  body?: Workspace
  onSuccess: () => any
  onCancel: () => any
}

const WorkspaceForm: FC<WorkspaceFormProps> = ({ id, onSuccess, onCancel, body }) => {
  const { core } = useCore()

  const isDifferent = (client, vendor) => {
    return !(client === vendor && client && vendor)
  }

  const save = async ({ description, parties }) => {
    const body = { description, parties }

    await id
      ? core.command("navarik.bridge.workspace.update", { id, description })
      : core.command("navarik.bridge.workspace.create", body)

    onSuccess()
  }

  return (
    <Form onSubmit={save} data={{ ...body }}>
      <Form.Header>
        <Title>{id ? "Edit Workspace" : "New Workspace"}</Title>
      </Form.Header>

      <Form.Field id="description">
        <Form.Label>Description</Form.Label>
        <Form.Input type="text" field="description" />
        <Form.Validators.Required field="description">This field cannot be blank</Form.Validators.Required>
      </Form.Field>

      {!id && // Workspace parties are immutable
        <Module>
          <Text size="lg">Parties</Text>

          <Form.Field id="client">
            <Form.Label>Client</Form.Label>
            <ReferenceSelector field="parties.client.id" nameField="name" entityType="navarik.bridge.organization" />
            <Form.Validators.Required field="parties.client.id">This field cannot be blank</Form.Validators.Required>
          </Form.Field>

          <Form.Field id="vendor">
            <Form.Label>Vendor</Form.Label>
            <ReferenceSelector field="parties.vendor.id" nameField="name" entityType="navarik.bridge.organization" />
            <Form.Validators.Required field="parties.vendor.id">This field cannot be blank</Form.Validators.Required>
          </Form.Field>

          <Form.Validators.Constraint fields={['parties.client.id', 'parties.vendor.id']} constraint={isDifferent}>Client and vendor cannot be the same</Form.Validators.Constraint>

        </Module>
      }

      <Form.Footer>
        <SpacedBox align="spread">
          <Button type="submit" title="Save" icon="save" />
          <Button color="secondary" title="Cancel" icon="cancel" onClick={onCancel} />
        </SpacedBox>
      </Form.Footer>
    </Form>
  )
}

export default WorkspaceForm
