import { FC } from 'react'
import { Form, SpacedBox, Title, Button } from "../../../components"
import { useCore } from '../../../core'

interface ServiceAccountFormProps {
  id?: string
  body: {
    tenant: string
    description?: string
  }
  onSuccess: () => any
  onCancel: () => any
}

const ServiceAccountForm: FC<ServiceAccountFormProps> = ({ id, onSuccess, onCancel, body }) => {
  const { core } = useCore()

  const save = async ({ description }) =>  {
    const tenant = body.tenant

    await id
      ? core.command("navarik.id.serviceAccount.update", { tenant, id, description })
      : core.command("navarik.id.serviceAccount.create", { tenant, description })

    onSuccess()
  }

  return (
    <Form onSubmit={save} data={{ ...body }}>
      <Form.Header>
        <Title>{id ? "Edit Service Account" : "New Service Account"}</Title>
      </Form.Header>

      <Form.Field id="description">
        <Form.Label>Description</Form.Label>
        <Form.Input type="text" field="description" />
      </Form.Field>

      <Form.Footer>
        <SpacedBox align="spread">
          <Button type="submit" title="Save" icon="save" />
          <Button color="secondary" title="Cancel" icon="cancel" onClick={onCancel} />
        </SpacedBox>
      </Form.Footer>
    </Form>
  )
}

export default ServiceAccountForm
