import { FC } from "react"
import styled from "styled-components"
import filesize from "filesize"
import mime from "mime"
import { Text } from "../index"

interface FileCardProps {
  name: string
  format: string
  src?: string
  size: number
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1rem;
`

const FileCard: FC<FileCardProps> = ({ name, format, size, src }) => {
  const fileSize = filesize(size, { round: 2 })
  const type = mime.getExtension(format)

  return (
    <Container>
      <Text size="sm" cropped>{name}</Text>
      <Text color="light" size="xsm">{type} | {fileSize}</Text>
    </Container>
  )
}

export default FileCard
