import { CoreDocument } from "@navarik/core-client-sdk"
import { FC } from "react"
import { useOverlay, Avatar } from "../../components"
import { useCommandResult } from "../../core"
import MyProfile from "./my-profile"
import { UserAccount } from "./types"

const MyAvatar: FC<{}> = () => {
  const { open: openTray } = useOverlay("tray")

  const userAccount = useCommandResult<CoreDocument<UserAccount>>("navarik.id.user.account.get")
  if (!userAccount) {
    return <Avatar size="sm" />
  }

  const viewMyProfile = () => openTray(
    <MyProfile onBack={viewMyProfile} />
  )

  return <Avatar url={userAccount.body.avatar} size="sm" onClick={viewMyProfile} />
}

export default MyAvatar
