import { useState } from "react"

export const useWaiting = (onStart = () => {}, onComplete = () => {}) => {
  const [isWaiting, setWaiting] = useState(false)

  const trigger = async () => {
    if (isWaiting) {
      return
    }

    setWaiting(true)
    await onStart()
    setWaiting(false)

    onComplete()
  }

  return { trigger, isWaiting }
}
