import { FC } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from "react-responsive"
import { sortChildren } from "../../utils"
import { Title } from '../typography'
import Header from './header'
import Sidebar from "./sidebar"

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0;
`

const HeaderSpace = styled.div<{ short?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3.75rem;
  background-color: ${props => props.theme.background.dark};
  box-shadow 1px 2px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
`

const TopNav = styled.div<{ expanded?: boolean }>`
  width: ${({ expanded = false }) => expanded ? "calc(100% - 3.75rem)" : "calc(100% - 200px)"};
  height: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.background.inverted};
`

const LogoSpace = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: ${({ collapsed = false }) => collapsed ? "3.75rem" : "200px"};
  padding: 0.75rem;
`

const Logo = styled.img`
  height: 100%;
  cursor: pointer;
  padding-right: 0.5rem;
`

const SidebarSpace = styled.div<{ collapsed?: boolean }>`
  height: 100%;
  width: ${({ collapsed = false }) => collapsed ? "3.75rem" : "200px"};
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: ${props => props.theme.background.dark};
  border-right: 1px solid #292d32;
`

const ContentSpace = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: calc(100% - 3.75rem);
  background-color: ${props => props.theme.background.flat};
`

const MainArea = styled.main<{ expanded?: boolean }>`
  width: ${({ expanded = false }) => expanded ? "calc(100% - 3.75rem)" : "calc(100% - 200px)"};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem;
  overflow: hidden;
`

interface AppLayoutProps {
  logo: any
  short?: boolean
  appName?: string
  onClickLogo?: () => void
}

type AppLayoutType = FC<AppLayoutProps> & {
  Header: typeof Header
  Sidebar: typeof Sidebar
}

const AppLayout: AppLayoutType = ({ children, short = false, logo, appName, onClickLogo = () => {} }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const [header, sidebar, ...main] = sortChildren([Header, Sidebar], children)

  return (
    <Page>
      <HeaderSpace>
        <LogoSpace collapsed={short} onClick={onClickLogo}>
          <Logo src={logo}></Logo>
          {!short && <Title color='inverted'>{appName}</Title>}
        </LogoSpace>

        <TopNav expanded={short}>
          {header}
        </TopNav>
      </HeaderSpace>

      <ContentSpace>
      {(!isTabletOrMobile) &&
        <SidebarSpace collapsed={short}>
          {sidebar}
        </SidebarSpace>
      }

        <MainArea expanded={short}>
          {main}
        </MainArea>
      </ContentSpace>

    </Page>
  )
}

AppLayout.Header = Header
AppLayout.Sidebar = Sidebar

export { AppLayout }