import { createContext, useState, useEffect, FC } from 'react'

export const DevModeContext = createContext<any>({
  showId: false,
  setShowId: () => { }
})

interface DevModeProviderProps {
  enabled: boolean
}

export const DevModeProvider: FC<DevModeProviderProps> = ({ children, enabled }) => {
  const [showContent, setShowContent] = useState(JSON.parse(localStorage.getItem('showContent') || '{}').showContent || false)

  useEffect(() => {
    localStorage.setItem('showContent', JSON.stringify({ showContent }))
  }, [showContent])

  const flipDisplayDebugInfo = () => {
    setShowContent(!showContent)
  }

  return (
    <DevModeContext.Provider value={{ enabled, showContent, setShowContent, flipDisplayDebugInfo }}>
      {children}
    </DevModeContext.Provider>
  )
}
