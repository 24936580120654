import { useState } from 'react'
import useDeepCompareEffect from "use-deep-compare-effect"
import { useCore } from './core-context'

export function useCommandResult<T = any>(command: string, args: object = {}, defaultResult?: T) {
  const [result, update] = useState<T|undefined>(defaultResult)
  const { core } = useCore()

  useDeepCompareEffect(() => {
    let mounted = true
    core.command(command, args)
      .then((result) => mounted && update(<T>result))

    return () => {
      mounted = false
    }
  }, [command, args, core])

  return result
}
