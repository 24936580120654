import { useEffect, useState } from "react"
import { CoreDocument, QueryOptions } from "@navarik/core-client-sdk"
import { useCore } from "./core-context"

interface State<T> {
  collection: Array<CoreDocument<T>>
  total: number
}

export function useQuery<T extends object>(filter: object, options: QueryOptions = {}): State<T> {
  const [collection, updateCollection] = useState<Array<CoreDocument<T>>>([])
  const [total, updateTotal] = useState<number>(0)
  const { core } = useCore()

  const { limit = 100, offset = 0, sort = { field: "created_at", direction: "desc", type: "date" }} = options
  useEffect(() => {
    let mounted = true
    const query = core.query<T>(filter)

    const observerId = query.observe(collection => {
      if (mounted) {
        updateCollection(collection)
        updateTotal(query.total)
      }
    })

    query.setOptions({ limit, offset, sort })
    if (query.changed) {
      updateCollection(query.currentValue())
      updateTotal(query.total)
    } else {
      query.refresh()
    }

    return () => {
      mounted = false
      query.unobserve(observerId)
    }
  }, [filter, limit, offset, sort, core])

  return { collection, total }
}
