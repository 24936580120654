import { FC } from "react"
import styled from 'styled-components'
import { ActionIcons } from "../types"
import { useWaiting } from "../hooks/use-waiting"
import { Text } from "../typography"
import { WaitingIcon } from "./waiting-icon"

interface ContainerProps {
  type?: "button"|"reset"|"submit"
  color?: "primary"|"secondary"|"danger"|"disabled"
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  disabled?: boolean
  onClick?: (event) => void
}

const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  color: ${({ theme }) => theme.textColor.inverted};
  background-color: ${({ theme, color = "primary" }) => theme.actionColor[color]};
  border: 1px solid ${({ theme, color = "primary" }) => theme.actionColor[color]};
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: ${({ disabled = false }) => disabled ? "arrow" : "pointer"};
  font-size: ${({ theme, size = "sm" }) => theme.fontSizes[size]};
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  ms-text-overflow: ellipsis;

  &:hover {
    opacity: ${({ disabled = false }) => disabled ? 1 : 0.75};
  }
`

interface ButtonProps {
  icon?: ActionIcons
  type?: "button"|"reset"|"submit"
  title?: string
  onClick?: () => void
  onComplete?: () => void
  color?: "primary"|"secondary"|"danger"
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  disabled?: boolean
}

export const Button: FC<ButtonProps> = ({ icon, onClick, onComplete, type = "button", size = "sm", title = "", disabled = false, color = "primary", children }) => {
  const { trigger: handleClick, isWaiting } = useWaiting(
    onComplete ? onClick : () => {},
    onComplete || onClick
  )

  return (
    <Container
      type={type}
      onClick={(event) => { handleClick(); event.stopPropagation(); } }
      disabled={disabled}
      color={disabled ? "disabled" : color}
      size={size}
    >
      {icon && <WaitingIcon type={icon} size={size} color="inverted" waiting={isWaiting} />}
      {title && <Text color="inverted">{title}</Text>}
      {children}
    </Container>
  )
}
