import { CoreDocument } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { Panel, useOverlay, Text, LinkButton, Spinner, Title, List, DebugInfo, SpacedBox } from "../components"
import { useCommandResult, useCore } from '../core'
import { MyMembership } from './current-user/types'
import Settings from './settings'

interface NewUserPageProps {
}

const NewUserPage: FC<NewUserPageProps> = () => {
  const { open: openTray, close: closeTray } = useOverlay("tray")
  const { switchPersona } = useCore()

  const myMemberships = useCommandResult<Array<CoreDocument<MyMembership>>>(
    "navarik.id.tenant.membership.listMine", {}, []
  )
  if (!myMemberships) {
    return <Spinner />
  }

  const startNewTenant = () => openTray(
    <Settings.TenantForm onClose={closeTray} />,
    { size: "small" },
    closeTray
  )

  return (myMemberships.length
    ? <Panel>
        <Title position="center">Choose your account</Title>
        <List>
          {myMemberships.map(({ id, body: { tenant } }) =>
            <List.Item key={`account_in_tenant_${tenant.id}`} onClick={() => switchPersona(id)}>
              <></>
              <SpacedBox>
                <DebugInfo>{tenant.id}</DebugInfo>
                <Text size="m">{tenant.name}</Text>
              </SpacedBox>
              <></>
            </List.Item>
          )}
        </List>
      </Panel>
    : <Panel>
        <Text size="m" position="center">You are currently not a member of an organization.</Text>
        <Text size="m" position="center">
          You can wait to be invited into one or <LinkButton size="m" onClick={startNewTenant}>create your own</LinkButton>.
        </Text>
      </Panel>
  )
}

export default NewUserPage
