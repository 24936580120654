import { FC } from "react"
import styled from 'styled-components'

interface LinkButtonProps {
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  disabled?: boolean
  onClick?: () => void
}

const StyledWrapper = styled.a<LinkButtonProps>`
  wordBreak: break-all;
  color: ${({ theme }) => theme.actionColor.primary};
  margin-bottom: 0;
  font-size: ${({ theme, size = "sm" }) => theme.fontSizes[size]};
  overflow: hidden;
  white-space: nowrap;
  ms-text-overflow: ellipsis;
  text-decoration: underline;
  cursor: ${({ disabled = false }) => disabled ? "default" : "pointer"};
`

export const LinkButton: FC<LinkButtonProps> = (props) =>
  <StyledWrapper
    {...props}
    onClick={!props.disabled && props.onClick ? props.onClick : () => {}}
  />
