import { FC } from 'react'
import { Form, SpacedBox, Button, Title } from "../../components"
import { useCore } from '../../core'
import { Tenant } from './types'

interface TenantFormProps {
  id?: string
  version_id?: string
  body?: Tenant
  onClose: () => any
}

const TenantForm: FC<TenantFormProps> = ({ id, version_id, onClose, body = {} }) => {
  const { core, switchPersona } = useCore()

  const update = async (body) =>  {
    await core.command("navarik.id.tenant.profile.update", { id, versionId: version_id, name: body.name })
    onClose()
  }

  const create = async (body) =>  {
    const tenant= await core.command("navarik.id.tenant.profile.create", { name: body.name })
    const myPersonas = await core.command("navarik.id.tenant.membership.listMine", { name: body.name })

    const newPersona = myPersonas.find(x => x.body.tenant.id === tenant.id)
    await switchPersona(newPersona.id)
  }

  return (
    <Form onSubmit={id ? update : create} data={{ ...body }}>
      <Form.Header>
        <Title>{id ? "Edit Organization Profile" : "Create Organization"}</Title>
      </Form.Header>

      <Form.Field id="name">
        <Form.Label>Name</Form.Label>
        <Form.Input type="text" field="name" />
        <Form.Validators.Required field="name">This field cannot be blank</Form.Validators.Required>
      </Form.Field>

      <Form.Footer>
        <SpacedBox align="spread">
          <Button type="submit" title="Save" icon="save" />
          <Button color="secondary" title="Cancel" icon="cancel" onClick={onClose} />
        </SpacedBox>
      </Form.Footer>
    </Form>
  )
}

export default TenantForm
