import { useCommandResult, useDocument } from "../../core"
import { ServiceAccount, UserProfile } from "./types"

export const useProfile = (id) => {
  // whoIs command resolves an identity into a profile document which,
  // but since it's a command its result is not affected by the changes from the websocket feed.
  // So we use observable document functionality in order to subscribe to these changes.
  const profile = useCommandResult("navarik.id.whoIs", { id })
  const profileDocument = useDocument<UserProfile|ServiceAccount>(profile && profile.id)

  return profileDocument
}
