import styled from 'styled-components'

interface TitleProps {
  position?: "left"|"center"|"right"
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlighted"|"inverted"|"danger"|"dark"
}

const Title = styled.span<TitleProps>`
  font-weight: 600;
  word-breaks: break-all;
  color: ${(({ theme, color = "main" }) => theme.textColor[color])};
  margin: auto 0;
  font-size: ${({ theme, size = "m" }) => theme.fontSizes[size]};
  align-self: ${({ theme, position = "left" }) => theme.positions[position]};
`

export default Title
