import { FC } from "react"
import PulseLoader from "react-spinners/PulseLoader"
import { ActionIcons, Size, TextColor } from "../types"
import Icon from "../icon"
import theme from "../theme"

interface WaitingIconProps {
  type: ActionIcons
  color?: TextColor
  size?: Size
  waiting?: boolean
}

const Spinner = () =>
  <PulseLoader size="0.25rem" color="white" css="height: 10px" />

const sizes = {
  xsm: "14px",
  sm: "16px",
  m: "18px",
  lg: "24px",
  xlg: "32px"
}

export const WaitingIcon: FC<WaitingIconProps> = ({ type, size = "m", color = "inherit", waiting = false }) => {
  const IconComponent = waiting ? Spinner : Icon[theme.actionIcons[type]]

  return (
    <IconComponent size={sizes[size]} style={{ color: theme.textColor[color], minWidth: sizes[size] }} />
  )
}
