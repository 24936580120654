import { FC, useState } from 'react'
import styled from "styled-components"

interface InputProps {
  onChange: (value: any) => void
  type?: string
  size?: "xsm" | "sm" | "m" | "lg" | "xlg"
  color?: "main" | "light" | "highlighted" | "inverted" | "danger"
  placeholder?: string
  autoFocus?: boolean
  value?: any
}

const Wrapper = styled.input<{ size }>`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  font-weight: 400;
  font-size: ${({ theme, size = "sm" }) => theme.fontSizes[size]};
  color: ${(({ theme, color = "main" }) => theme.textColor[color])};

  background-color: #fff;
  background-clip: padding-box;
  border: ${({ theme }) => theme.border["main"]};
  border-radius: ${({ theme }) => theme.borderRadius};
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`

const typeParsers = {
  number: parseFloat,
  text: x => x
}

export const Input: FC<InputProps> = ({ onChange, size, color, value = "", placeholder = "", type = "text", autoFocus = false }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const valueParser = typeParsers[type] || typeParsers.text

  const onBlur = () => {
    onChange(valueParser(currentValue))
  }

  return (
    <Wrapper
      type={type}
      size={size}
      color={color}
      autoFocus={autoFocus}
      value={currentValue}
      placeholder={placeholder}
      onChange={(event) => setCurrentValue(event.target.value)}
      onKeyPress={(event) => (event.key === 'Enter') && onBlur()}
      onBlur={onBlur}
    />
  )
}
