import { FC, PropsWithChildren } from 'react'
import styled from "styled-components"

interface PaneProps {
  name: string
  title: string
}

const PaneContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: ${(props) => props.onClick ? "pointer" : "default"};
`

export const Pane: FC<PropsWithChildren<PaneProps>> = ({ children }) =>
  <PaneContainer>
    {children}
  </PaneContainer>
