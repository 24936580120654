import { FC } from 'react'
import styled from 'styled-components'
import Item from './item'

type MenuType = FC & {
  Item: typeof Item
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: spread;
  padding: 0;
  margin: 0;
`

const Menu: MenuType = ({ children }) =>
  <Container>
    {children}
  </Container>

Menu.Item = Item

export default Menu
