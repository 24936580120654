import { FC } from 'react'
import styled from 'styled-components'
import { sortChildren } from '../../utils'
import Item from './item'
import Section from './section'
import { Footer } from "./footer"

type MainMenuType = FC & {
  Section: typeof Section
  Item: typeof Item
  Footer: typeof Footer
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: spread;
  padding: 0;
  margin: 0;
`

const Main = styled.menu`
  height: 100%;
  padding: 0;
  margin: 0;
`

const MainMenu: MainMenuType = ({ children }) => {
  const [footer, ...main] = sortChildren([Footer], children)

  return (
    <Container>
      <Main>{main}</Main>
      {footer}
    </Container>
  )
}

MainMenu.Item = Item
MainMenu.Section = Section
MainMenu.Footer = Footer

export default MainMenu
