import { FC } from "react"
import { Avatar, Icon, theme } from "../../components"
import { useProfile } from "./use-user-profile"

interface UserAvatarProps {
  id: string
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
}

const SIZES = {
  xsm: '32px',
  sm: '40px',
  m: '48px',
  lg: '80px',
  xlg: '128px'
}

const UserAvatar: FC<UserAvatarProps> = ({ id, size = "m" }) => {
  const user = useProfile(id)
  if (!user) {
    return <Avatar size={size} />
  }

  return user.type === "navarik.id.user.profile"
    ? <Avatar url={user.body["avatar"] } size={size} />
    : <Icon.Settings color={theme.textColor.light} size={SIZES[size]} />
}

export default UserAvatar
