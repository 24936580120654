import { FC } from "react"
import { List } from "../../components"
import { useQuery } from '../../core'
import { Event } from "./types"
import Note from "./note"
import Attachment from "./attachment"
import DefaultActivityEvent from "./default-activity-event"

interface ActivityFeedProps {
  jobId: string
  readOnly?: boolean
}

const eventTypes = {
  "navarik.bridge.event": DefaultActivityEvent ,
  "navarik.bridge.note": Note.ActivityEvent,
  "navarik.bridge.attachment": Attachment.ActivityEvent
}

const ActivityFeed: FC<ActivityFeedProps> = ({ jobId, readOnly = false }) => {
  const { collection: events } = useQuery<Event>({
    operator: "and",
    args: [
      { operator: "in", args: ["type", Object.keys(eventTypes) ] },
      { operator: "eq", args: ["body.document", jobId] },
    ]
  }, {
    limit: 1000,
    offset: 0,
    sort: { field: "created_at", direction: "asc", type: "date" }
  })

  return (
    <List>
      {events.map((event) => {
        const Component = eventTypes[event.type]
        return <Component  key={`activity_event_${event.id}`} {...event} />
      })}
    </List>
  )
}

export default ActivityFeed
