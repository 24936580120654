import styled from 'styled-components'

interface TabProps {
  active?: boolean
}

export const Tab = styled.div<TabProps>`
  min-width: 80px;
  min-height: 2rem;
  padding: 0.5rem 1rem;
  border-bottom: ${props => props.active ? props.theme.border.active : "1px solid transparent"};
  cursor: pointer;
  line-height: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 3;

  &:hover {
    border-bottom: ${({ theme }) => props => props.active ? props.theme.border.active : theme.border.main};
  }
`
