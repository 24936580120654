import { CoreDocument } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { List, useOverlay, Button, SpacedBox, Title, Text, IconButton, DebugInfo } from "../../../components"
import { useCore, useQuery } from '../../../core'
import { ConfirmationModal, ReferenceName } from '../../../shared'
import { Workspace } from '../types'
import WorkspaceForm from './workspace-form'

interface WorkspacesListProps {
}

const WorkspacesList: FC<WorkspacesListProps> = () => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useOverlay("tray")
  const { open: openModal, close: closeModal } = useOverlay("modal")

  const { collection: workspaces } = useQuery<Workspace>({
    type: "navarik.bridge.workspace"
  })

  const createWorkspace = () => openTray(
    <WorkspaceForm onSuccess={closeTray} onCancel={closeTray} />,
    { size: "large" }
  )

  const editWorkspace = (workspace: CoreDocument<Workspace>) => openTray(
    <WorkspaceForm {...workspace} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  const deleteWorkspace = async (workspace: CoreDocument<Workspace>) =>
    openModal(
      <ConfirmationModal
        text={`Are you sure you want to delete workspace "${workspace.body.description}"?`}
        onConfirm={() => core.command("navarik.bridge.workspace.delete", { id: workspace.id })}
        onCancel={closeModal}
        onDone={closeModal}
      />,
      { title: "Confirm delete workspace." }
    )

  return (
    <List columns={["30%", "30%", "30%", "12%"]}>
      <List.Header>
        <Title size="sm">DESCRIPTION</Title>
        <Title size="sm">CLIENT</Title>
        <Title size="sm">VENDOR</Title>

        <SpacedBox align='end'>
          <Button onClick={createWorkspace} icon="add" title="Create New" />
        </SpacedBox>
      </List.Header>

      {workspaces.map((item) =>
      <List.Item key={`client_secrets_list_item_${item.id}`}>
        <SpacedBox><Text data-test-id={item.id}>{item.body.description}</Text><DebugInfo>{item.id}</DebugInfo></SpacedBox>
        <SpacedBox><ReferenceName id={item.body.parties.client.id} /><DebugInfo>{item.body.parties.client.id}</DebugInfo></SpacedBox>
        <SpacedBox><ReferenceName id={item.body.parties.vendor.id} /><DebugInfo>{item.body.parties.vendor.id}</DebugInfo></SpacedBox>

        <SpacedBox align='end'>
          <IconButton onComplete={() => editWorkspace(item)} icon="edit" title="Edit" />
          <IconButton onComplete={() => deleteWorkspace(item)} icon="delete" title="Delete" />
        </SpacedBox>
      </List.Item>
    )}
    </List>
  )
}

export default WorkspacesList
