import { FC, cloneElement } from "react"
import styled from 'styled-components'
import { sortChildren } from "../../utils"
import { Header } from "./header"
import { Item } from "./item"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.background.inverted};
`

const ScrollBase = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  visibility: hidden;

  &:hover {
    visibility: visible;
  }
`

const ListBody = styled.ul`
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  visibility: visible;
`

interface ListProps {
  columns?: Array<string>
}

type ListType = FC<ListProps> & {
  Item: typeof Item
  Header: typeof Header
}

const List: ListType = ({ columns = [], children }) => {
  const [header, ...items] = sortChildren([Header], children)

  return (
    <Container>
      {header && cloneElement(header, { columns: header.props.columns || columns })}

      <ScrollBase>
        <ListBody>
          {items.map(x => x && cloneElement(x, { columns: x.props.columns || columns }))}
        </ListBody>
      </ScrollBase>
    </Container>
  )
}

List.Item = Item
List.Header = Header

export default List
