import { FC } from 'react'
import styled from 'styled-components'
import Icon from '../icon'

interface ItemProps {
  onClick?: () => void
  current?: boolean
  icon?: string
  short?: boolean
  title: string
}

interface ContainerProps {
  current?: boolean
  onClick?: (event: any) => void
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: ${({ theme, current }) => current ? theme.textColor.inverted : "#BFD2E4"};
  line-height: ${({ theme }) => theme.fontSizes.lg};
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  padding: 1rem 1rem 1rem calc(1rem - 2px);
  margin: 0.5rem 0;
  border-left: 2px solid ${({ theme, current }) => current ? theme.background.main : "transparent" };

  &:hover {
    background-color: #00254B;
  }
`

interface TextProps {
  current?: boolean
  onClick?: (event: any) => void
}

const TitleText = styled.div<TextProps>`
  font-size: ${({ theme, current }) => current ? theme.fontSizes.m : theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.fontSizes.lg};
  padding: 0 0.5rem;
  margin: auto 0;
`

const MainMenuItem: FC<ItemProps> = ({ icon, title, short = false, current = false, onClick = () => {} }) => {
  const IconComponent = icon ? Icon[icon] : Icon.Square

  return (
    <Container
      onClick={onClick ? (event) => { event.stopPropagation(); onClick() } : undefined}
      current={current} title={title}
    >
      <IconComponent size="22px" />
      {!short && <TitleText>{title}</TitleText>}
    </Container>
  )
}

export default MainMenuItem
