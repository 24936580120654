import { FC, CSSProperties } from 'react'
import Icon from './icon'

type BellProps = {
  count?: number,
  onClick: () => void
}

const containerStyle: CSSProperties = {
  position: 'relative',
  padding: "15px",
  cursor: "pointer"
}

const badgeStyle: CSSProperties = {
  position: "absolute",
  right: "10px",
  top: "8px",
  height: "17px",
  width: "17px",
  borderRadius: "50%",
  backgroundColor: "red",
  fontSize: "10px",
  textAlign: "center",
  color: "white"
}

const NotificationBell: FC<BellProps> = ({ count = 0, onClick }) => {
  const notificationCountLimit = 19
  const notificationCount = count < notificationCountLimit ? count : `${notificationCountLimit}+`

  return (
    <div style={containerStyle} onClick={onClick}>
      <Icon.Bell color="white" size={24} />
      {count > 0 &&
        <div style={badgeStyle}>
          {notificationCount}
        </div>
      }
    </div>
  )
}

export default NotificationBell
