import { FC } from "react"
import styled from 'styled-components'
import mime from "mime"
import { Icon } from "../index"

const SIZES = {
  sm: "2em",
  m: "3em",
  lg: "5em"
}

interface WrapperProps {
  size: "sm"|"m"|"lg"
}

const IconWrapper = styled.div<WrapperProps>`
  max-width: ${(props) => SIZES[props.size]};
  max-height: ${(props) => SIZES[props.size]};
`

type FileFormatIconProps = {
  format: string
  src?: string
  size?: "sm"|"m"|"lg"
}

const FileFormatIcon: FC<FileFormatIconProps> = ({ format, size = "sm" }) =>
  <IconWrapper size={size}>
    <Icon.File />
    {mime.getExtension(format)}
  </IconWrapper>

export default FileFormatIcon
