import { FC } from 'react'
import { Form, SpacedBox, Button, Title } from '../../../components'
import { useCore } from '../../../core'
import { Note } from '../types'

interface NoteFormProps {
  id?: string
  body: Partial<Note>
  onSuccess: () => void
  onCancel: () => void
}

const NoteForm: FC<NoteFormProps> = ({ onSuccess, onCancel, id, body }) => {
  const { core } = useCore()

  const save = async (data: Note) => {
    await id
      ? core.command("navarik.bridge.note.update", { id, ...data })
      : core.command("navarik.bridge.note.create", data)

    onSuccess()
  }

  return (
    <Form onSubmit={save} data={body}>
      <Form.Header>
        <Title>{id ? "Edit Note" : "Add Note"}</Title>
      </Form.Header>

      <Form.Field>
        <Form.TextArea field="content" placeholder="" />
        <Form.Validators.Required field="content">This field cannot be blank</Form.Validators.Required>
      </Form.Field>

      <Form.Footer>
        <SpacedBox align="spread">
          <Button type="submit" title="Save" icon="save" />
          <Button color="secondary" title="Cancel" icon="cancel" onClick={onCancel} />
        </SpacedBox>
      </Form.Footer>
    </Form>
  )
}

export default NoteForm
