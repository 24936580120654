import { FC } from 'react'
import { Spinner, Tabs, Section, Toolbar } from "../../components"
import { useCore, useDocument } from '../../core'
import { TenantMembership } from './types'
import Membership from "./membership"
import TenantForm from './tenant-form'
import ServiceAccount from './service-account'
import BridgeOrganization from './bridge-organization'
import Workspace from './workspace'
import CatalogSchema from './catalog-schema'
import TenantSummary from './tenant-summary'

interface SettingsProps {
  tab?: string
  onTabChange: (tab: string) => void
}

type SettingsFeatureType = FC<SettingsProps> & {
  TenantForm: typeof TenantForm
}

const Settings: SettingsFeatureType = ({ tab = "users", onTabChange }) => {
  const { session } = useCore()
  const currentMembership = useDocument<TenantMembership>(session.persona)
  if (!currentMembership) {
    return <Spinner />
  }

  return (
    <Section>
      <Section.Header>
        <Toolbar>
          <TenantSummary id={currentMembership.body.tenant} />
        </Toolbar>
      </Section.Header>

      <Tabs active={tab} onChange={onTabChange}>
        <Tabs.Pane name="users" title="Users">
          <Membership.List tenantId={currentMembership.body.tenant} />
        </Tabs.Pane>
        <Tabs.Pane name="service-accounts" title="Service Accounts">
          <ServiceAccount.List tenantId={currentMembership.body.tenant} />
        </Tabs.Pane>
        <Tabs.Pane name="organizations" title="Organizations">
          <BridgeOrganization.List tenantId={currentMembership.body.tenant} />
        </Tabs.Pane>
        <Tabs.Pane name="workspaces" title="Workspaces">
          <Workspace.List />
        </Tabs.Pane>
        {/* <Tabs.Pane name="catalogs" title="Catalogs">
          <CatalogSchema.List tenantId={currentMembership.body.tenant} />
        </Tabs.Pane> */}
      </Tabs>
    </Section>
  )
}

Settings.TenantForm = TenantForm

export default Settings
