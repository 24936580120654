import { CoreDocument } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { Text } from '../../components'
import { useCommandResult } from '../../core'
import { MyMembership } from './types'

const CurrentTenant: FC<{}> = () => {
  const currentPersona = useCommandResult<CoreDocument<MyMembership>>("navarik.id.tenant.membership.getCurrent")
  if (!currentPersona) {
    return null
  }

  return (
    <Text>{currentPersona.body.tenant.name}</Text>
  )
}

export default CurrentTenant
