import objectPath from "object-path"

export const groupBy = (property: string, data: Array<object>) => {
  const result = {}

  for (const item of data) {
    const key = objectPath.get(item, property)
    if (!result[key]) {
      result[key] = []
    }

    result[key].push(item)
  }

  return result
}
