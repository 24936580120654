import { FC } from 'react'
import styled from 'styled-components'
import Icon from '../icon'

interface ContainerProps {
  current?: boolean
  onClick?: (event: any) => void
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: ${({ theme, current }) => current ? theme.textColor.main : theme.textColor.light};
  background-color: ${({ theme, current }) => current ? theme.background.flat : "transparent"};
  line-height: ${({ theme }) => theme.fontSizes.lg};
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  border-left: 2px solid ${({ theme, current }) => current ? theme.background.main : "transparent" };
  padding: 1rem;

  &:hover {
    background-color: ${({ theme, current }) => current ? theme.background.flat : theme.background.light};
  }
`

interface TextProps {
  current?: boolean
  onClick?: (event: any) => void
}

const TitleText = styled.div<TextProps>`
  font-size: ${({ theme, current }) => current ? theme.fontSizes.m : theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.fontSizes.lg};
  padding: 0 0.5rem;
  margin: auto 0;
`

interface ItemProps {
  onClick?: () => void
  current?: boolean
  icon?: string
  title: string
}

const MenuItem: FC<ItemProps> = ({ icon, title, current = false, onClick = () => {} }) => {
  const IconComponent = icon && Icon[icon]

  return (
    <Container
      onClick={onClick ? (event) => { event.stopPropagation(); onClick() } : undefined}
      current={current} title={title}
    >
      {icon && <IconComponent size="22px" />}
      <TitleText>{title}</TitleText>
    </Container>
  )
}

export default MenuItem
