import { FC } from "react"
import { Text } from "../../components"
import { useProfile } from "./use-user-profile"

interface UserEmailProps {
  id: string
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
}

const UserEmail: FC<UserEmailProps> = ({ id, size = "sm" }) => {
  const user = useProfile(id)
  if (!user || !user.body["email"]) {
    return null
  }

  return <Text size={size}>{user.body["email"]}</Text>
}

export default UserEmail
