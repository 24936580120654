import { useEffect, useState } from "react"
import { CoreDocument } from "@navarik/core-client-sdk"
import { useCore } from "./core-context"

type State<T> = CoreDocument<T>|undefined|null

export function useDocument<T extends object>(id?: string|null): State<T> {
  const [current, update] = useState<State<T>>(null)
  const { core } = useCore()

  useEffect(() => {
    if (!id) {
      return () => {}
    }

    let mounted = true
    const document = core.getDocument<T>(id)
    const observerId = document.observe(x => mounted && update(x))

    if (document.changed) {
      update(document.currentValue())
    } else {
      document.refresh()
    }

    return () => {
      mounted = false
      document.unobserve(observerId)
    }
  }, [id, core])

  return current
}
