import { FC } from 'react'
import styled from 'styled-components'
import { Size } from './types'
import { Label, Title } from './typography'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const LabelSpace = styled.span`
  padding-right: 1rem;
`

interface FieldProps {
  title: string
  size?: Size
}

const labelSize: { [key: string]: Size } = {
  xsm: "xsm",
  sm: "xsm",
  m: "sm",
  lg: "m",
  xlg: "lg"
}

export const Field: FC<FieldProps> = ({ title, children, size = "sm" }) =>
  <Container>
    <LabelSpace>
      <Label size={labelSize[size]}>{title}</Label>
    </LabelSpace>

    <Title size={size} color="dark">{children}</Title>
  </Container>
