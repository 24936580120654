import styled from 'styled-components'

interface ImageProps {
  src: string
  size?: 'xsm'|'sm'|"m"|'lg'|'xlg'
  shape?: 'circle'|'square'
  onClick?: () => void
  crossOrigin?: string
}

const SIZES = {
  xsm: '2rem',
  sm: '2.5rem',
  m: '3rem',
  lg: '5rem',
  xlg: '8rem'
}

const Image = styled.img<ImageProps>`
  display: flex;
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-width: ${({ size = "m"}) => SIZES[size]};
  max-height: ${({ size = "m"}) => SIZES[size]};
  border-radius: ${({ theme, shape = "square" }) => shape === "square" ? theme.borderRadius : "50%"};
  background-color: ${({ theme }) => theme.background["inverted"] };
  cursor: ${({ onClick }) => onClick ? "pointer" : "default"};
`

export default Image