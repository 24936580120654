import { FC } from "react"
import { Dictionary } from "@navarik/types"
import { StatusIndicator } from "../../components"

interface JobStatusIndicator {
  status: "pending"|"active"|"complete"|"cancelled"
}

const statusColors: Dictionary<"green"|"orange"|"red"|"grey"> = {
  pending: "red",
  active: "orange",
  complete: "green",
  cancelled: "grey"
}

const statusText = {
  pending: "Pending",
  active: "In progress",
  complete: "Complete",
  cancelled: "Cancelled"
}

const JobStatusIndicator: FC<JobStatusIndicator> = ({ status }) =>
  <StatusIndicator status={statusColors[status]} text={statusText[status]} />

export default JobStatusIndicator
