import { FC } from 'react'
import { Text, Spinner, Size } from "../../components"
import { useDocument } from '../../core'

interface ReferenceNameProps {
  id: string
  nameField?: string
  size?: Size
  color?: "main"|"light"|"highlighted"|"inverted"|"danger"
}

export const ReferenceName: FC<ReferenceNameProps> = ({ id, nameField = "name", size = "sm", color = "main" }) => {
  const reference = useDocument(id)
  if (!reference) {
    return <Spinner />
  }

  const name = reference.body[nameField]

  return (
    <Text size={size} color={color}>{name}</Text>
  )
}
