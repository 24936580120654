import { FC } from 'react'
import { TextArea } from "../../controls"
import { useForm } from "../form-context"

interface TextAreaProps {
  field: string
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlighted"|"inverted"|"danger"
  placeholder?: string
  autoFocus?: boolean
  onTextArea?: (value: string) => void
}

const FormTextArea: FC<TextAreaProps> = ({ field, onTextArea = () => {}, ...props }) => {
  const { setField, getField } = useForm()

  const onChange = (value) => {
    setField(field, value)
    onTextArea(value)
  }

  return (
    <TextArea
      {...props}
      value={getField(field)}
      onChange={onChange}
    />
  )
}

export default FormTextArea
