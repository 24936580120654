import { FC } from "react"
import { Button, Title, Form, SpacedBox } from "../../../components"
import { useCore } from "../../../core"
import { TenantMembership } from "../types"

interface MembershipFormProps {
  id: string
  body: TenantMembership
  onClose: () => void
}

const MembershipForm: FC<MembershipFormProps> = ({ id, body, onClose }) => {
  const { core } = useCore()

  const save = async ({ role }) => {
    if (role !== body.role) {
      await core.command("navarik.id.tenant.membership.changeRole", { tenant: body.tenant, id, role })
    }

    onClose()
  }

  const roles = {
    admin: "Administrator",
    user: "User"
  }

  return (
    <Form onSubmit={save} data={{ role: "user" }}>
      <Form.Header>
        <Title>Modify User</Title>
      </Form.Header>

      <Form.Field>
        <Form.Label>Role</Form.Label>
        <Form.Select field="role" options={roles} />
        <Form.Validators.Required field="role">This field cannot be blank</Form.Validators.Required>
      </Form.Field>

      <Form.Footer>
        <SpacedBox align="spread">
          <Button type="submit" title="Save" icon="save" />
          <Button color="secondary" title="Cancel" icon="cancel" onClick={onClose} />
        </SpacedBox>
      </Form.Footer>
    </Form>
  )
}

export default MembershipForm
