import { CoreDocument } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { List, useOverlay, Button, SpacedBox, Title, Text, IconButton, DebugInfo } from "../../../components"
import { useQuery, useCore } from '../../../core'
import { ConfirmationModal } from '../../../shared'
import { BridgeOrganization } from '../types'
import BridgeOrganizationForm from './bridge-organization-form'

interface BridgeOrganizationListProps {
  tenantId: string
}

const BridgeOrganizationList: FC<BridgeOrganizationListProps> = ({ tenantId }) => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useOverlay("tray")
  const { open: openModal, close: closeModal } = useOverlay("modal")

  const { collection: bridgeOrganizations } = useQuery<BridgeOrganization>({
    type: "navarik.bridge.organization",
    "body.audience": tenantId
  })

  const createRegistryRecord = () => openTray(
    <BridgeOrganizationForm onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  const editRegistryRecord = (id: string, body: BridgeOrganization) => openTray(
    <BridgeOrganizationForm id={id} body={body} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  const deleteRegistryRecord = async (org: CoreDocument<BridgeOrganization>) =>
    openModal(
      <ConfirmationModal
        text={`Are you sure you want to delete Bridge organization "${org.body.name}"?`}
        onConfirm={() => core.command("navarik.bridge.organization.delete", { id: org.id })}
        onCancel={closeModal}
        onDone={closeModal}
      />,
      { title: "Confirm delete bridge organization." }
    )

  return (
    <List columns={["40%", "40%", "20%"]}>
      <List.Header>
        <Title size="sm">NAME</Title>
        <Title size="sm">CATEGORY</Title>

        <SpacedBox align='end'>
          <Button onClick={createRegistryRecord} icon="add" title="Register" />
        </SpacedBox>
      </List.Header>

      {bridgeOrganizations.map(item =>
        <List.Item key={`bridge_organization_${item.id}`}>
          <SpacedBox><Text>{item.body.name}</Text><DebugInfo>{item.id}</DebugInfo></SpacedBox>
          <Text>{item.body.category}</Text>

          <SpacedBox align='end'>
            <IconButton onComplete={() => editRegistryRecord(item.id, item.body)} icon="edit" title="Edit" />
            <IconButton onComplete={() => deleteRegistryRecord(item)} icon="delete" title="Delete" />
          </SpacedBox>
        </List.Item>
      )}
    </List>
  )
}

export default BridgeOrganizationList
