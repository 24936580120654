import React, { FC, ReactElement, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../buttons'
import { useForm, FormContext } from "../form-context"
import RemoveItemIcon from './remove-item-icon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Wrapper = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 1rem 0;
  margin: 0;
`

const ArrayItem = styled.li`
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
`

interface ArrayFieldProps {
  field: string
  children: ReactElement
}

const ArrayField: FC<ArrayFieldProps> = ({ field, children }) => {
  const { getField, setField, registerValidator } = useForm()
  const fieldValues = getField(field) || []
  const [rows, setRows] = useState(fieldValues.map((_) => true))

  const addRow = () => setRows([...rows, true])
  const removeRow = (id) => {
    setField(field, fieldValues.reduce((acc, next, index) => (index !== id ? [...acc, next] : acc), []))
    rows.pop()
    setRows([...rows])
  }

  const rowContext = (index) => {
    const normalizeName = name => name ? `${field}.${index}.${name}` : `${field}.${index}`
    return {
      getField: (name) => getField(normalizeName(name)),
      setField: (name, value) => setField(normalizeName(name), value),
      registerValidator,
      submitForm: () => {}
    }
  }

  return (
    <Container>
      <Wrapper>
        {rows.map((_, index) =>
          <ArrayItem key={`form_field_${field}_${index}`}>
            <FormContext.Provider value={rowContext(index)}>
              {React.cloneElement(children)}
              <RemoveItemIcon onClick={() => removeRow(index)} />
            </FormContext.Provider>
          </ArrayItem>
        )}
      </Wrapper>
      <Button onClick={addRow} color="primary" title="Add" icon='add' />
    </Container>
  )
}

export default ArrayField
