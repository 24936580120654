import { FC } from 'react'
import { SpacedBox, Text, Section, Button, Panel } from "../../components"

interface ConfirmationModalProps {
  text: string
  onConfirm: () => void|Promise<void>
  onDone: () => void
  onCancel: () => void
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({ text, onConfirm, onDone, onCancel }) =>
  <Section>
    <Panel>
      <SpacedBox direction="column">
        <Text>{text}</Text>
      </SpacedBox>
    </Panel>

    <Section.Footer>
      <SpacedBox align="spread">
        <Button onClick={onConfirm} title="Confirm" icon="ok" onComplete={onDone} />
        <Button onClick={onCancel} title="Cancel" icon="cancel" color="secondary" />
      </SpacedBox>
    </Section.Footer>
  </Section>
