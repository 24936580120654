import { FC } from "react"
import { Button, Title, Form } from "../../../components"
import { useCore } from "../../../core"

interface InvitationFormProps {
  tenantId: string
  onSuccess: () => void
}

const InvitationForm: FC<InvitationFormProps> = ({ tenantId, onSuccess }) => {
  const { core } = useCore()

  const invite = async ({ email, role }) => {
    const user = await core.command("navarik.id.user.getByEmail", { email })
              || await core.command("navarik.id.user.createByEmail", { email })

    await core.command("navarik.id.tenant.membership.create", {
      tenant: tenantId,
      user: user.id,
      role
    })

    onSuccess()
  }

  const isUnique = async (email: string) => {
    if (!email) {
      return true
    }

    const user = await core.command("navarik.id.user.getByEmail", { email })
    if (!user) {
      return true
    }

    const result = await core.command("navarik.id.tenant.membership.list", {
      tenant: tenantId,
      user: user.id
    })

    return !result.length
  }

  const roles = {
    admin: "Administrator",
    user: "User"
  }

  return (
    <Form onSubmit={invite} data={{ role: "user" }}>
      <Form.Header>
        <Title>Add User</Title>
      </Form.Header>

      <Form.Field>
        <Form.Label>Role</Form.Label>
        <Form.Select field="role" options={roles} />
        <Form.Validators.Required field="role">This field cannot be blank</Form.Validators.Required>
      </Form.Field>

      <Form.Field>
        <Form.Label>Email</Form.Label>
        <Form.Input type="email" placeholder="email" field="email" />
        <Form.Validators.Required field="email">This field cannot be blank</Form.Validators.Required>
        <Form.Validators.Constraint fields={["email"]} constraint={isUnique}>User is already a member of the organization</Form.Validators.Constraint>
      </Form.Field>

      <Button type="submit" title="Add User" />
    </Form>
  )
}

export default InvitationForm
