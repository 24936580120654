import { FC, useState, useEffect } from 'react'
import { Switch, Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { AppLayout, Title, MainMenu, Spinner, ToggleSwitch } from "../components"
import { useCore, useDocument } from '../core'
import Settings from "./settings"
import { TenantMembership } from './settings/types'
import CurrentUser from './current-user'
import NewUserPage from './new-user-page'
import Inspection from './inspection'
import Catalog from './catalog'
import logo from "./logo.png"

type UiState = "full" | "short"

const pageTitles = {
  "/settings": "Settings",
  "/inspection": "Inspection Jobs",
  "/catalogs": "Catalogs",
}

export const MainPage: FC<{}> = () => {
  const [state, setState] = useState<UiState>(JSON.parse(localStorage.getItem('state') ||'{}').state || "short")
  const flipState = () => setState(state === "full" ? "short" : "full")

  useEffect(() => {
    localStorage.setItem('state',JSON.stringify({state}))
  },[state])

  const history = useHistory()
  const location = useLocation()

  const { session } = useCore()
  const currentMembership = useDocument<TenantMembership>(session.persona)
  if (currentMembership === null) {
    return <Spinner />
  }

  const currentRoute = `/${location.pathname.split("/")[1]}`
  const navigateTo = url => history.push(url)

  const isAdmin = currentMembership && currentMembership.body.role === "admin"

  return (
    <AppLayout short={state === "short"} logo={logo} appName="Bridge" onClickLogo={() => navigateTo("/")}>
      <AppLayout.Header>
        <Title size="m">{pageTitles[currentRoute]}</Title>
        <CurrentUser />
      </AppLayout.Header>

      {currentMembership &&
        <AppLayout.Sidebar>
          <MainMenu>
            <MainMenu.Item
              short={state === "short"}
              icon="CheckSquare"
              title="Inspection"
              onClick={() => navigateTo("/inspection")}
              current={currentRoute === "/inspection"}
            />
            {/* <MainMenu.Item
              short={state === "short"}
              icon="Book"
              title="Catalogs"
              onClick={() => navigateTo("/catalogs")}
              current={currentRoute === "/catalogs"}
            /> */}
            {isAdmin &&
              <MainMenu.Item
                short={state === "short"}
                icon="Settings"
                title="Settings"
                onClick={() => navigateTo("/settings")}
                current={currentRoute === "/settings"}
              />
            }

            <MainMenu.Footer>
              {state === 'full' && <ToggleSwitch />}
              <MainMenu.Item
                short={state === "short"}
                icon={state === "short" ? "ChevronRight" : "ChevronLeft"}
                title="Collapse"
                onClick={flipState}
                current={false}
              />
            </MainMenu.Footer>
          </MainMenu>
        </AppLayout.Sidebar>
      }

      {!currentMembership &&
        <NewUserPage />
      }

      {currentMembership &&
        <Switch>
          <Redirect exact from="/" to="/inspection" />

          <Route exact path="/settings">
            <Settings onTabChange={tab => navigateTo(`/settings/${tab}`)} />
          </Route>

          {isAdmin &&
          <Route exact path="/settings/:tab" render={({ match: { params: { tab } } }) =>
            <Settings tab={tab} onTabChange={tab => navigateTo(`/settings/${tab}`)} />
          } />
          }

          <Route exact path="/inspection">
            <Inspection.List onJobSelection={id => navigateTo(`/inspection/jobs/${id}`)} />
          </Route>

          <Route exact path="/inspection/jobs/:id" render={({ match: { params: { id } } }) =>
            <Inspection.Job id={id} />
          } />

          {/* <Route exact path="/catalogs">
            <Catalog onTabChange={tab => navigateTo(`/catalogs/${tab}`)} />
          </Route>

          <Route exact path="/catalogs/:tab" render={({ match: { params: { tab } } }) =>
            <Catalog tab={tab} onTabChange={tab => navigateTo(`/catalogs/${tab}`)} />
          } /> */}
        </Switch>
      }
    </AppLayout>
  )
}
