import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Text, useOverlay, Spinner, Tabs, SplitScreen, Section, Toolbar, SpacedBox, Title, Button, Field } from "../../components"
import { useDocument } from '../../core'
import { Job } from './types'
import Comment from './note'
import Worksheet from './worksheet'
import JobStatusIndicator from './job-status-indicator'
import ActivityFeed from './activity-feed'
import Attachment from './attachment'

interface JobViewProps {
  id: string
}

const JobView: FC<JobViewProps> = ({ id }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const { open: openTray, close: closeTray } = useOverlay("tray")

  const job = useDocument<Job>(id)
  if (!job) {
    return <Spinner />
  }

  const editJob = () => openTray(
    <Worksheet.Form
      id={id}
      body={job.body}
      onSuccess={closeTray}
      onCancel={closeTray}
    />,
    { size: "large" }
  )

  const openUploadForm = () => openTray(
    <Attachment.UploadForm
      documentId={id}
      workspaceId={job.body.workspace}
      onCancel={closeTray}
    />,
    { size: "small" }
  )

  const openNoteForm = () => openTray(
    <Comment.Form
      body={{ document: id, workspace: job.body.workspace }}
      onSuccess={closeTray}
      onCancel={closeTray}
    />,
    { size: "small" }
  )

  // const requestAction = () => openTray(
  //   <ActionRequest.Form body={{ parent: jobId }} onSuccess={closeTray} onCancel={closeTray} />,
  //   { size: "large" }
  // )

  const isClosed = job.body.status === "complete" || job.body.status === "cancelled"

  const mainTabs = [
    <Tabs.Pane key="summary_tab" name="worksheet" title="Worksheet">
      <Worksheet.Summary body={job.body} />
    </Tabs.Pane>
  ]

  if (job.body.requested.quantity) {
    mainTabs.push(
      <Tabs.Pane key="quantity_tab" name="quantity" title="Quantity">
      {/* <QuantityResults.List jobId={id} /> */}
      </Tabs.Pane>
    )
  }

  if (job.body.requested.quality) {
    mainTabs.push(
      <Tabs.Pane key="quality_tab" name="quality" title="Quality">
        {/* <QualityResult.List jobId={id} /> */}
      </Tabs.Pane>
    )
  }

  if (job.body.requested.timelog) {
    mainTabs.push(
      <Tabs.Pane key="time_log_tab" name="time_log" title="Time Log">
        {/* <TimeLog.List jobId={id} /> */}
      </Tabs.Pane>
    )
  }

  mainTabs.push(
    <Tabs.Pane key="files_tab" name="files" title="Files">
      {/* <FileList parentId={id} readOnly={isClosed} /> */}
    </Tabs.Pane>
  )

  const feedTab =
    <Tabs.Pane name="feed" title="Activity Feed">
      <ActivityFeed key="feed_tab" jobId={id} readOnly={isClosed} />
    </Tabs.Pane>

  return (
    <Section>
      <Section.Header>
        <Toolbar>
          <SpacedBox direction='row' align='spread'>
            <Field title='Trip Number' size='sm'>{job.body.tripNumber}</Field>
            <JobStatusIndicator status={job.body.status} />
          </SpacedBox>
        </Toolbar>
      </Section.Header>

      {isTabletOrMobile
        ? <Tabs>
            {mainTabs}
            {feedTab}
          </Tabs>
        : <SplitScreen>
            <SplitScreen.Left>
              <Tabs>{mainTabs}</Tabs>
            </SplitScreen.Left>

            <SplitScreen.Right>
              <Tabs>{feedTab}</Tabs>
            </SplitScreen.Right>
          </SplitScreen>
      }

      {!isClosed &&
        <Section.Footer>
          <SpacedBox align='end'>
            <Button title="Add Note" icon="add" onClick={openNoteForm} />
            <Button title="Upload File" icon="attachment" onClick={openUploadForm} />
            {/* <Button title="Request Action" icon="mail" onClick={requestAction} /> */}
          </SpacedBox>
        </Section.Footer>
      }
    </Section>
  )}

export default JobView
