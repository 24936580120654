import { FC, useState } from 'react'
import { CoreDocument } from '@navarik/core-client-sdk'
import { List, useOverlay, Button, SpacedBox, Title, Text, IconButton, LinkButton, Section, DebugInfo } from "../../../components"
import { useCore, useQuery } from '../../../core'
import { ConfirmationModal, Paginator } from '../../../shared'
import { groupBy } from '../../../utils'
import { ClientSecret, ServiceAccount } from '../types'
import ServiceAccountForm from './service-account-form'
import ServiceAccountView from './service-account-view'

interface ServiceAccountsListProps {
  tenantId: string
}

const ServiceAccountsList: FC<ServiceAccountsListProps> = ({ tenantId }) => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useOverlay("tray")
  const { open: openModal, close: closeModal } = useOverlay("modal")
  const [page, setPage] = useState(0)
  const itemsPerPage = 20

  const { collection: serviceAccounts, total  } = useQuery<ServiceAccount>({
    type: "navarik.id.serviceAccount",
    "body.tenant": tenantId
  }, {
    limit: itemsPerPage,
    offset: itemsPerPage * page
  })

  const { collection: clientSecrets } = useQuery<ClientSecret>({
    operator: "and",
    args: [
      { operator: "eq", args: ["type", "navarik.id.clientSecret"] },
      { operator: "in", args: ["body.serviceAccount", serviceAccounts.map(x => x.id)] }
    ]
  }, { limit: 1000 })

  const secretsByServiceAccount = groupBy("body.serviceAccount", clientSecrets)

  const viewServiceAccount = (serviceAccount: CoreDocument<ServiceAccount>) => openTray(
    <ServiceAccountView tenantId={tenantId} id={serviceAccount.id} />,
    { size: "large" }
  )

  const createServiceAccount = () => openTray(
    <ServiceAccountForm body={{ tenant: tenantId }} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  const editServiceAccount = (serviceAccount: CoreDocument<ServiceAccount>) => openTray(
    <ServiceAccountForm {...serviceAccount} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  const deleteServiceAccount = async (serviceAccount: CoreDocument<ServiceAccount>) =>
    openModal(
      <ConfirmationModal
        text={`Are you sure you want to delete service account "${serviceAccount.body.description}"?`}
        onConfirm={() => core.command("navarik.id.serviceAccount.delete", { tenant: tenantId, id: serviceAccount.id })}
        onCancel={closeModal}
        onDone={closeModal}
      />,
      { title: "Confirm delete service account." }
    )

  return (
    <Section>
      <List columns={["30%", "30%", "30%", "12%"]}>
        <List.Header>
          <Title size="sm">DESCRIPTION</Title>
          <Title size="sm">CLIENT ID</Title>
          <Title size="sm">CLIENT SECRETS</Title>

          <SpacedBox align='end'>
            <Button onClick={createServiceAccount} icon="add" title="Create New" />
          </SpacedBox>
        </List.Header>

        {serviceAccounts.map(item =>
          <List.Item key={`service_account_${item.id}`}>
            <SpacedBox><Text>{item.body.description}</Text><DebugInfo>{item.id}</DebugInfo></SpacedBox>
            <Text>{item.body.clientId}</Text>

            <LinkButton onClick={() => viewServiceAccount(item)}>
              {!secretsByServiceAccount[item.id]
                ? "No secrets"
                : `${secretsByServiceAccount[item.id].length} secret(s)`}
            </LinkButton>

            <SpacedBox align='end'>
              <IconButton onComplete={() => viewServiceAccount(item)} icon="key" title="Manage Secrets" />
              <IconButton onComplete={() => editServiceAccount(item)} icon="edit" title="Edit" />
              <IconButton onComplete={() => deleteServiceAccount(item)} icon="delete" title="Delete" />
            </SpacedBox>
          </List.Item>
        )}
      </List>

      <Section.Footer>
        <Paginator page={page} totalPages={Math.ceil(total/itemsPerPage)} onPageChange={setPage} />
      </Section.Footer>
    </Section>
  )
}

export default ServiceAccountsList
