import { FC } from "react"
import styled from 'styled-components'
import { FileRejection, useDropzone } from "react-dropzone"
import Icon from "../icon"

const Container = styled.div`
  padding: 20px 10px 10px 10px;
  outline: none;
  text-align: center;
  color: #8f8f8f;
`

const FileUploadDropZone = styled.div`
  padding: 1.5em;
  transition: box-shadow 0.3s;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  justify-items: center;
  align-items: center;
  outline: none;

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #cccccc2b;
    cursor: pointer;
  }
`

interface FileUpload {
  file: File
  status: "accepted"|"rejected"
  feedback: Array<string>
}

interface DropzoneProps {
  onDrop: (fileUploads: Array<FileUpload>) => void
  maxSize: number // In MB
}

const Dropzone: FC<DropzoneProps> = ({ onDrop, maxSize }) => {
  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
      const result: Array<FileUpload> = []
      for (const file of acceptedFiles) {
        result.push({ file, status: "accepted", feedback: [] })
      }
      for (const { file, errors } of fileRejections) {
        result.push({ file, status: "rejected", feedback: errors.map(x => x.message) })
      }
      onDrop(result)
    },
    maxSize: maxSize * 1024 * 1024
  })

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <FileUploadDropZone>
        <Icon.UploadCloud size={26} />
        {isDragActive
          ? <div>Drop the file to upload.</div>
          : <div>Drop a file to upload or Browse.</div>
        }
      </FileUploadDropZone>
    </Container>
  )
}

export default Dropzone
