import { FC, Children, PropsWithChildren } from "react"
import styled from 'styled-components'

type Directions = "row"|"column"
type Alignment = "start"|"end"|"center"|"spread"

interface SpacedBoxProps {
  direction?: Directions
  align?: Alignment
  onClick?: () => void
}

const Wrapper = styled.div<{ align: Alignment, direction: Directions }>`
  width: 100%;
  display: flex;
  justify-content: ${({ theme, align }) => theme.positions[align]};
  flex-direction: ${({ direction }) => direction};
  ${({ direction }) => direction === "column" ? "height: 100%;" : ""}
  overflow: hidden;
`

const RowElement = styled.div`
  margin-right: 0;
  margin-left: 0.25rem;
  flex-shrink: 0;

  &:first-child {
    margin-left: 0;
  }
`

const ColumnElement = styled.div`
  width: 100%;
  margin-top: 0;
  margin-top: 0.25rem;
  flex-shrink: 0;

  &:first-child {
    margin-top: 0;
  }
`

export const SpacedBox: FC<PropsWithChildren<SpacedBoxProps>> = ({ children, onClick, align = "start", direction = "row" }) => {
  const Element = direction === "column" ? ColumnElement : RowElement

  return (
    <Wrapper align={align} direction={direction} onClick={onClick}>
      {Children.toArray(children).map((child, i) =>
        <Element key={`part_${i}`}>{child}</Element>
      )}
    </Wrapper>
  )
}
