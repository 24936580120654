import { FC } from 'react'
import { Text, List, Button, SpacedBox, useOverlay, Timestamp, Title, IconButton } from "../../../components"
import { useCore, useQuery } from '../../../core'
import { ConfirmationModal } from '../../../shared'
import { newestFirst } from '../../../utils'
import { ClientSecret } from '../types'
import GeneratedSecret from './generated-secret'

interface ClientSecretListProps {
  tenantId: string
  serviceAccountId: string
}

const ClientSecretList: FC<ClientSecretListProps> = ({ tenantId, serviceAccountId }) => {
  const { core } = useCore()
  const { open: openModal, close: closeModal } = useOverlay("modal")

  const { collection: clientSecrets } = useQuery<ClientSecret>({
    type: "navarik.id.clientSecret",
    "body.serviceAccount": serviceAccountId
  })

  const generateSecret = async () => {
    const secretDocument = await core.command("navarik.id.clientSecret.create", {
      tenant: tenantId,
      serviceAccount: serviceAccountId
    })
    const secret = secretDocument.body.secret

    await core.command("navarik.id.clientSecret.update", {
      tenant: tenantId,
      serviceAccount: serviceAccountId,
      id: secretDocument.id,
      description: `....${secret.substr(-4)}`
    })

    openModal(
      <GeneratedSecret secret={secret} onClick={closeModal} />,
      { title: "Client secret created successfully." }
    )
  }

  const deleteSecret = (secretId, description) => openModal(
    <ConfirmationModal
      text={`Are you sure you want to delete client secret ${secretId} (${description})?`}
      onConfirm={() => core.command("navarik.id.clientSecret.delete", {
        tenant: tenantId,
        serviceAccount: serviceAccountId,
        id: secretId
      })}
      onCancel={closeModal}
      onDone={closeModal}
    />,
    { title: "Confirm action." }
  )

  return (
    <List columns={["30%", "30%", "30%", "12%"]}>
      <List.Header>
        <Title size="sm">SECRET</Title>
        <Title size="sm">GENERATED AT</Title>

        <SpacedBox align='end'>
          <Button onClick={generateSecret} icon="add" title="Generate" />
        </SpacedBox>
      </List.Header>

      {clientSecrets.sort(newestFirst).map((item) =>
        <List.Item key={`client_secrets_list_istem_${item.id}`}>
          <Text>{item.body.description}</Text>
          <Text><Timestamp>{item.created_at}</Timestamp></Text>

          <SpacedBox align='end'>
            <IconButton onComplete={() => deleteSecret(item.id, item.body.description)} icon="delete" title="Delete" />
          </SpacedBox>
        </List.Item>
      )}
    </List>
  )
}

export default ClientSecretList
