import { FC, useState } from "react"
import { SpacedBox, File, Button, Form, List, IconButton, Feedback } from "../../../components"
import { useCore } from "../../../core"

interface UploadFormProps {
  workspaceId: string
  documentId: string
  onCancel: () => void
}

interface FileUpload {
  file: File
  status: "accepted"|"rejected"|"processing"|"done"
  feedback: Array<string>
}

const UploadForm: FC<UploadFormProps> = ({ workspaceId, documentId, onCancel }) => {
  const { core } = useCore()
  const [uploads, setUploads] = useState<Array<FileUpload>>([])

  const addFiles = (files) => setUploads(uploads.concat(files))
  const removeFile = (file) => setUploads(uploads.filter(x => x.file.name !== file.name))

  const uploadFiles = async () => {
    const newState = await Promise.all(uploads.map(async (upload) => {
      if (upload.status !== "accepted") {
        return upload
      }

      try {
        await core.upload("navarik.bridge.attachment.upload", {
          workspace: workspaceId,
          document: documentId,
          file: upload.file
        })

        upload.status = "done"
        upload.feedback = ["Uploaded successfully"]
      } catch (error: any) {
        upload.status = "rejected"
        upload.feedback = [error.message]
      }

      return upload
    }))

    setUploads(newState)
  }

  return (
    <Form onSubmit={uploadFiles}>
      <File.Dropzone maxSize={50} onDrop={addFiles} />

      <List columns={["15%", "75%", "11%"]}>
      {uploads.map(({ file, status, feedback }) =>
        <List.Item key={`upload_file_${file.name}`}>
          <File.Thumbnail size="sm" format={file.type} />

          <SpacedBox direction="column">
            <File.Card name={file.name} format={file.type} size={file.size} />
            <Feedback type={status === "rejected" ? "error" : "valid"} size="xsm">
              {feedback.join(", ")}
            </Feedback>
          </SpacedBox>

          <IconButton size="sm" icon="cancel" onClick={() => removeFile(file)} />
        </List.Item>
      )}
      </List>

      <Form.Footer>
        <SpacedBox align="spread">
          <Button type="submit" title="Upload" icon="save" />
          <Button color="secondary" title="Close" icon="exit" onClick={onCancel} />
        </SpacedBox>
      </Form.Footer>
    </Form>
  )
}

export default UploadForm
