import { FC } from 'react'
import { Spinner, Text, useOverlay, SpacedBox, LinkButton, DebugInfo } from "../../components"
import { useDocument } from '../../core'
import { Tenant } from './types'
import TenantForm from './tenant-form'

interface TenantSummaryProps {
  id: string
}

const TenantSummary: FC<TenantSummaryProps> = ({ id }) => {
  const { open: openTray, close: closeTray } = useOverlay("tray")

  const tenant = useDocument<Tenant>(id)
  if (!tenant) {
    return <Spinner />
  }

  const editTenantProfile = () => openTray(
    <TenantForm {...tenant} onClose={closeTray} />,
    { size: "small" }
  )

  return (
    <SpacedBox align="start">
      <SpacedBox><Text size="m">{tenant.body.name}</Text><DebugInfo>{tenant.id}</DebugInfo></SpacedBox>
      <LinkButton onClick={editTenantProfile}>Edit</LinkButton>
    </SpacedBox>
  )
}

export default TenantSummary
