import { Dictionary } from "@navarik/types"
import { FC } from "react"
import styled from 'styled-components'
import { ActionIcons, Size, TextColor } from "../types"
import { useWaiting } from "../hooks/use-waiting"
import { WaitingIcon } from "./waiting-icon"

interface ContainerProps {
  type?: "button"|"reset"|"submit"
  color?: TextColor
  disabled?: boolean
  onClick?: (event) => void
}

const Container = styled.button<ContainerProps>`
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  cursor: ${({ disabled = false }) => disabled ? "arrow" : "pointer"};
  color: ${({ theme, color = "main" }) => theme.textColor[color]};
  overflow: hidden;

  &:hover {
    ${({ theme, disabled = false }) => !disabled && "color: " + theme.textColor.highlighted}
  }
`

interface IconButtonProps {
  icon: ActionIcons
  title?: string
  type?: "button"|"reset"|"submit"
  color?: "primary"|"secondary"|"danger"
  size?: Size
  disabled?: boolean
  onClick?: () => void
  onComplete?: () => void
}

const colors: Dictionary<TextColor> = {
  primary: "main",
  secondary: "main",
  danger: "danger"
}

export const IconButton: FC<IconButtonProps> = ({ icon, onClick, onComplete = () => {}, type = "button", size = "sm", title = "", disabled = false, color = "primary" }) => {
  const { trigger: handleClick, isWaiting } = useWaiting(onClick, onComplete)
  const iconColor = disabled ? "light" : colors[color]

  return (
    <Container
      type={type}
      onClick={(event) => { handleClick(); event.stopPropagation(); } }
      disabled={disabled}
      color={iconColor}
      title={title}
    >
      <WaitingIcon
        type={icon}
        size={size}
        waiting={isWaiting}
      />
    </Container>
  )
}
