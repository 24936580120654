import { FC } from "react"
import styled from 'styled-components'
import FileFormatIcon from "./file-format-icon"

const IMAGE_SIZES = {
  sm: "4rem",
  m: "10rem",
  lg: "100%"
}

const Container = styled.div<{ size: "sm"|"m"|"lg" }>`
  display: flex;
  align-items: center;
  width: ${(props) => IMAGE_SIZES[props.size]};
  padding: 10px 0;

  &:hover {
    cursor: zoom-in;
  }
`

interface ImageProps {
  size: "sm"|"m"|"lg"
  crossOrigin: string
}

type ThumbnailProps = {
  format: string
  onClick?: () => void
  src?: string
  size?: "sm"|"m"|"lg"
}

const Thumbnail: FC<ThumbnailProps> = ({ format, src = "", size = "sm", onClick = () => {} }) =>
  <Container onClick={onClick} size={size}>
  {format.includes("image") && src
    ? <img src={src} alt={src} crossOrigin="use-credentials" style={{ width: "100%" }} />
    : <FileFormatIcon format={format} size={size} />
  }
  </Container>

export default Thumbnail
