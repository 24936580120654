import { BrowserRouter } from 'react-router-dom'
import { CoreProvider, LocalAuthClient, Auth0ClientAdapter } from './core'
import { ApplicationContainer, OverlayProvider, DevModeProvider } from "./components"
import { MainPage } from './features'
import { AppSelector } from './app-selector'
import { Tray } from './tray'
import { Modal } from './modal'

declare global {
  interface Window { CONFIG: any }
}

if (!window.CONFIG) {
  window.CONFIG = {}
}

const truth = ["true", "1", "yes", "on"]

const baseUrl = process.env.REACT_APP_BACKEND || window.CONFIG.backend || ''
const devmode = truth.includes(process.env.REACT_APP_DEVMODE || "") || window.CONFIG.devmode || false

const authContext = process.env.REACT_APP_AUTH_PROVIDER || window.CONFIG.authProvider || "local"
const authClient = authContext === 'local'
  ? new LocalAuthClient()
  : new Auth0ClientAdapter({
    domain: process.env.REACT_APP_AUTH_DOMAIN || window.CONFIG.authDomain || "",
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID || window.CONFIG.authClientId || "",
    redirectUri: window.location.origin
  })

const App = () =>
  <BrowserRouter>
    <ApplicationContainer>
      <CoreProvider authClient={authClient} baseUrl={baseUrl} onSessionChange={() => window.location.pathname = "/"}>
        <OverlayProvider names={["tray", "modal", "appSelector"]}>
          <DevModeProvider enabled={devmode}>
            <MainPage/>
            <AppSelector />
            <Tray />
            <Modal />
          </DevModeProvider>
        </OverlayProvider>
      </CoreProvider>
    </ApplicationContainer>
  </BrowserRouter>

export default App
