import styled from 'styled-components'

const Main = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export default Main
