import { CoreDocument } from "@navarik/core-client-sdk"
import { FC } from "react"
import { File, Label, SpacedBox, Text, Timestamp, Toast } from "../../../components"
import { useCore } from "../../../core"
import User from "../../../shared/user"

type AttachmentActivityEventProps = CoreDocument<{
  caption: string
  size?: number
  format: string
}>

const AttachmentActivityEvent: FC<AttachmentActivityEventProps> = (document) => {
  const { core } = useCore()

  const { id, created_by, created_at, body: { format, size = 0, caption } } = document

  return (
    <Toast>
      <Toast.IconSpace>
        <File.Thumbnail size="sm" src={core.contentUrl(id)} format={format} />
      </Toast.IconSpace>

      <SpacedBox direction="column">
        <SpacedBox align="spread">
          <User.Name id={created_by} />
          <Label size="xsm"><Timestamp>{created_at}</Timestamp></Label>
        </SpacedBox>

        <Text>Attached file</Text>
        <File.Card name={caption} format={format} size={size} />
      </SpacedBox>
    </Toast>
  )
}

export default AttachmentActivityEvent
