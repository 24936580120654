import { CoreDocument } from "@navarik/core-client-sdk"
import { FC } from "react"
import { Label, SpacedBox, Text, Timestamp, Toast } from "../../../components"
import User from "../../../shared/user"

type NoteActivityEventProps = CoreDocument<{
  author: string
  timestamp: string
  content: string
}>

const NoteActivityEvent: FC<NoteActivityEventProps> = ({ created_by, created_at, body: { content } }) =>
  <Toast>
    <Toast.IconSpace>
      <User.Avatar id={created_by} size="xsm" />
    </Toast.IconSpace>

    <SpacedBox direction="column">
      <SpacedBox align="spread">
        <User.Name id={created_by} />
        <Label size="xsm"><Timestamp>{created_at}</Timestamp></Label>
      </SpacedBox>

      <Text>{content}</Text>
    </SpacedBox>
  </Toast>

export default NoteActivityEvent
