import { useEffect, useState } from "react"
import { Observable } from "@navarik/types"

export function useObservable<T>(value: Observable<T>): T {
  const [current, update] = useState<T>(value.currentValue())

  useEffect(() => {
    let mounted = true
    const observerId = value.observe((value) => {
      if (mounted) {
        update(value)
      }
    })

    return () => {
      mounted = false
      value.unobserve(observerId)
    }
  }, [value, update])

  return current
}