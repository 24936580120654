const theme = {
  background: {
    main: "#004A97",
    dark: "#001B38",
    light: "#F8FBFE",
    highlighted: "#FBFDFE",
    flat: "#EBEFF3",
    inverted: "#FFFFFF"
  },
  textColor: {
    main: "#585858",
    light: "#727272",
    highlighted: "#004A97",
    inverted: "#FFFFFF",
    danger: "#944A00",
    success: "#259400",
    error: "#940C00"
  },
  positions: {
    start: "flex-start",
    end: "flex-end",
    left: "flex-start",
    right: "flex-end",
    center: "center",
    spread: "space-between"
  },
  actionColor: {
    disabled: "#727272",
    primary: "#004A97",
    secondary: "#944A00",
    danger: "#94004A"
  },
  actionIcons: {
    delete: "Trash2",
    add: "Plus",
    edit: "Edit",
    save: "Save",
    ok: "Check",
    cancel: "X",
    exit: "LogOut",
    key: "Key",
    attachment: "Paperclip",
    mail: "Mail",
    previous: "ArrowLeft",
    next: "ArrowRight",
    disable: "MinusSquare",
    enable: "PlusSquare"
  },
  fontSizes: {
    xsm: "0.75rem",
    sm: "0.825rem",
    m: "1rem",
    lg: "1.125rem",
    xlg: "1.5rem"
  },
  border: {
    main: "1px solid #ced4da",
    light: "1px solid #EBEFF3",
    active: "2px solid #004A97"
  },
  borderRadius: "0.25rem"
};

export default theme