import styled from 'styled-components'

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  overflow: hidden;
`

export default Header
