import { Children, FC } from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.background.light};
  border-bottom: ${({ theme }) => theme.border.light};
`

const Column = styled.div<{ width?: string }>`
  width: ${props => props.width || "auto"};
`

interface HeaderProps {
  columns?: Array<string>
}

export const Header: FC<HeaderProps> = ({ columns = [], children }) =>
  <Container>
    {Children.toArray(children).map((child, i) =>
      <Column width={columns[i]} key={`columt_${i}`}>{child}</Column>
    )}
  </Container>
