import { CoreDocument } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { useObservable, useOverlay, List, Divider, Label, Text, DebugInfo, SpacedBox } from '../../components'
import { useCommandResult, useCore } from '../../core'
import { ConfirmationModal } from '../../shared'
import { MyMembership } from './types'

const MyPersonas: FC<{}> = () => {
  const { core, switchPersona } = useCore()
  const { open: openModal, closeAll } = useOverlay("modal")

  const session = useObservable(core.session)
  const myMemberships = useCommandResult<Array<CoreDocument<MyMembership>>>(
    "navarik.id.tenant.membership.listMine", {}, []
  )
  if (!myMemberships || myMemberships.length <= 1) {
    return null
  }

  const confirmPersonaSwitch = async (id, tenantName) => openModal(
    <ConfirmationModal
      text={`Do you want to switch to your account at ${tenantName}?`}
      onConfirm={() => { }}
      onCancel={closeAll}
      onDone={() => switchPersona(id)}
    />,
    { title: "Switch Account" }
  )

  return (
    <>
      <Divider><Label>Switch Account</Label></Divider>
      <List>
        {myMemberships.map(({ id, body: { tenant } }) => id !== session.persona &&
          <List.Item key={`tenant_${tenant.id}`} onClick={() => confirmPersonaSwitch(id, tenant.name)}>
            <SpacedBox><Text size="m">{tenant.name}</Text><DebugInfo>{tenant.id}</DebugInfo></SpacedBox>
          </List.Item>
        )}
      </List>
    </>
  )
}

export default MyPersonas
