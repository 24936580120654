import { FC } from "react"
import styled from "styled-components"
import { sortChildren } from "../../utils"
import { IconSpace } from "./icon-space"

interface ToastProps {
  onClick?: () => void
}

const Wrapper = styled.div`
  display: flex;

  margin: 0.5rem 0.5rem 0 0.5rem;
  line-height: 0;
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props.theme.border["main"]};
  cursor: ${props => props.onClick ? "pointer" : "default"};

  &:last-child {
    margin-bottom: 0.5rem;
  }
`

const MainSpace = styled.div`
  width: 100%;
  line-height: 1.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0;
`

type ToastType = FC<ToastProps> & {
  IconSpace: typeof IconSpace
}

export const Toast: ToastType = ({ children, onClick }) => {
  const [iconSpace, ...main] = sortChildren([IconSpace], children)

  return (
    <Wrapper onClick={onClick}>
      {iconSpace}

      <MainSpace>
        {main}
      </MainSpace>
    </Wrapper>
  )
}

Toast.IconSpace = IconSpace
