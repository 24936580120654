import { CoreDocument } from "@navarik/core-client-sdk"
import { FC } from "react"
import { Label, SpacedBox, Text, Timestamp, Toast } from "../../components"
import User from "../../shared/user"

type DefaultActivityEventProps = CoreDocument<{
  author: string
  timestamp: string
  comment: string
}>

const DefaultActivityEvent: FC<DefaultActivityEventProps> = ({ body: { author, timestamp, comment } }) =>
  <Toast>
    <Toast.IconSpace>
      <User.Avatar id={author} size="xsm" />
    </Toast.IconSpace>

    <SpacedBox direction="column">
      <SpacedBox align="spread">
        <User.Name id={author} />
        <Label size="xsm"><Timestamp>{timestamp}</Timestamp></Label>
      </SpacedBox>

      <Text>{comment}</Text>
    </SpacedBox>
  </Toast>

export default DefaultActivityEvent
