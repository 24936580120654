import { CoreClient, Session } from "@navarik/core-client-sdk"
import { FC, createContext, useContext, useState } from 'react'
import { AsyncContent, useEffectOnce } from "../components"
import { AuthClientAdapter } from "./types"

interface CoreContextInterface {
  core: CoreClient
  session: Session
  login: () => Promise<void>
  switchPersona: (id: string) => Promise<void>
  logout: () => void
}

const CoreContext = createContext<any>({})

interface CoreProviderProps {
  baseUrl: string
  onSessionChange: () => void
  authClient: AuthClientAdapter
}

let core: CoreClient
const getCoreInstance = (baseUrl, identityProvider) => {
  if (!core) {
    core = new CoreClient({ baseUrl, identityProvider })
  }

  return core
}

export const CoreProvider: FC<CoreProviderProps> = ({ children, baseUrl, authClient, onSessionChange }) => {
  const core = getCoreInstance(baseUrl, authClient)
  const [isInitializing, setInitialization] = useState(true)
  const [isCoreConnected, setIsCoreConnected] = useState(true)
  const [session, setSession] = useState<Session>({ isAuthenticated: false, rootUserId: null, persona: null })

  core.session.observe((newValue) => {
    if (JSON.stringify(session) === JSON.stringify(newValue)) {
      return
    }

    if (session.persona && newValue.persona !== session.persona) {
      onSessionChange()
    } else {
      setSession(newValue)
    }
  })

  const login = async () => {
    await authClient.init()

    if (!authClient.isAuthenticated) {
      await authClient.login()
      return
    }

    try {
      await core.connect()
      setInitialization(false)
    } catch (e) {
      setIsCoreConnected(false)
    }
  }

  const switchPersona = async (id: string) => {
    setInitialization(true)

    await core.command("navarik.ui.preferences.update", { currentPersona: id })

    try {
      await core.switchPersona(id)
    } catch (e) {
      setIsCoreConnected(false)
    }
  }

  const logout = async () => {
    setInitialization(true)
    await core.disconnect()
    await authClient.logout()
  }

  useEffectOnce(login)

  const contextValue: CoreContextInterface = {
    core,
    session,
    login,
    switchPersona,
    logout
  }

  return (
    <CoreContext.Provider value={contextValue}>
      <AsyncContent
        size="xlg"
        loading={isInitializing}
        failed={!isCoreConnected}
        retry={async () => await logout()}
      >
        {!isInitializing && children}
      </AsyncContent>
    </CoreContext.Provider>
  )
}

export const useCore = () => useContext<CoreContextInterface>(CoreContext)
