import { Children, FC } from "react"
import styled from "styled-components"

const Container = styled.li`
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => theme.border.light};
  cursor: ${({ onClick }) => onClick ? "pointer" : "default"};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${({ theme }) => theme.background.highlighted};
  }
`

const Column = styled.div<{ width?: string }>`
  width: ${props => props.width || "auto"};
`

interface ItemProps {
  columns?: Array<string>
  onClick?: () => void
}

export const Item: FC<ItemProps> = ({ columns = [], onClick, children }) => {
  return (
    <Container onClick={onClick}>
      {Children.toArray(children).map((child, i) =>
        <Column width={columns[i]} key={`column_${i}`}>
          {child}
        </Column>
      )}
    </Container>
  )
}
