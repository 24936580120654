import styled from "styled-components"

export const IconSpace = styled.div`
  min-height: 4rem;
  min-width: 4rem;
  height: 100%;
  margin-right: 0.5rem;
  padding: 1rem;
  background-color: ${props => props.theme.background.light};
`
