import Dropzone from "./dropzone"
import Preview from "./file-preview"
import Thumbnail from "./thumbnail"
import Card from "./file-card"

export const File = {
  Dropzone,
  Card,
  Preview,
  Thumbnail
}
