import { FC } from "react"
import styled from 'styled-components'
import { Icon, useOverlay, Text } from "./components"

const sizes = {
  lg: "700px",
  large: "700px",
  sm: "350px",
  small: "350px",
}

type TraySize = "sm"|"small"|"lg"|"large"

interface PropsWithSize {
  size: TraySize
}

const Container = styled.div<PropsWithSize>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(255, 255, 255);
  width: ${({ size }) => sizes[size]};
  max-width: 90vw;
  overflow: hidden;
  height: 100vh;
  padding: 0;
  background: white;

  position: absolute;
  z-index: 30;
  top: 0;
  right: ${({ size }) => "-" + sizes[size]};
  animation: 0.25s fadeIn;
  animation-delay: 0.05s;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    100% {
      right: 0;
    }
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 2rem;
  line-height: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
`

export const Tray: FC<{}> = () => {
  const { isOpen, parameters, close, content } = useOverlay<{ size: TraySize }>("tray")

  if (!isOpen) {
    return null
  }

  const size = parameters.size || "small"

  return (
    <Container size={size}>
      <CloseButton onClick={close}>
        <Text size="sm" color="light">close</Text>
        <Icon.ChevronRight color="#727272" size="18px" />
      </CloseButton>

      <Content>{content}</Content>
    </Container>
  )
}
