import { FC } from 'react'
import { Text, SpacedBox, Button } from "../components"

interface PaginatorProps {
  page: number
  totalPages: number
  onPageChange: (page: number) => void
}

export const Paginator: FC<PaginatorProps> = ({ page, totalPages, onPageChange }) => {
  const nextPage = () => onPageChange(page + 1)
  const previousPage = () => page > 0 && onPageChange(page - 1)

  return (
    <SpacedBox align='center'>
      <Button icon='previous' onClick={previousPage} disabled={!totalPages || page === 0} />
      {!totalPages
        ? <Text>Page 0 of 0</Text>
        : <Text>Page {page + 1} of {totalPages}</Text>
      }
      <Button icon='next' onClick={nextPage} disabled={!totalPages || page >= totalPages - 1} />
    </SpacedBox>
  )
}
