import styled from 'styled-components'

const Backdrop = styled.div`
  background: #000;
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  z-index: 25;
  opacity: 0.5;
`

export default Backdrop
