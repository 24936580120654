import styled from 'styled-components'

export const TabSwitch = styled.div`
  width: 100%;
  flex-basis: 2rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  border-bottom: ${({ theme }) => theme.border.light};
`
