import styled from 'styled-components'
import { sortChildren } from '../../utils'
import Header from "./header"
import Footer from "./footer"
import Main from "./main"
import Sidebar from './sidebar'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
`

const Content = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
`

const Section = ({ children }) => {
  const [header, footer, sidebar, ...main] = sortChildren([Header, Footer, Sidebar], children)

  return (
    <Wrapper>
      {header && <>{header}</>}

      <Content>
        {sidebar && <>{sidebar}</>}
        <Main>
          {main}
        </Main>
      </Content>

      {footer && <>{footer}</>}
    </Wrapper>
  )
}

Section.Header = Header
Section.Footer = Footer
Section.Sidebar = Sidebar

export default Section
