import { FC } from 'react'
import { Field, FieldCluster, SpacedBox, Panel } from '../../../components'
import { ReferenceName } from '../../../shared'
import Activities from '../activities'
import { Job } from "../types"

interface WorksheetSummaryProps {
  body: Job
}

const WorksheetSummary: FC<WorksheetSummaryProps> = ({ body }) =>
  <Panel>
    <SpacedBox direction="column">
      <SpacedBox direction="row" align="spread">
        <SpacedBox direction="column">
          <Field title="Client"><ReferenceName id={body.client} /></Field>
          <Field title="Vendor"><ReferenceName id={body.vendor} /></Field>
        </SpacedBox>

        <SpacedBox direction="column">
          <Field title="Client Ref #">{body.refNumber}</Field>
          <Field title="Vendor Ref #">{body.inspectorReferenceNumber}</Field>
        </SpacedBox>
      </SpacedBox>

      <FieldCluster>
        {/* <Field title="Activity"><Activities.Name id={body.activity} /></Field> */}
        {/* <Field title="Location"><CatalogMapping.Name id={body.location} /></Field>
        <Field title="Product"><CatalogMapping.Name id={body.cargo.product} /></Field> */}
        {/* <Field title="Quantity">{body.cargo.quantity} <CatalogMapping.Name id={body.cargo.uom} /></Field> */}
      </FieldCluster>

      {/* <Divider>Transports</Divider>
      {body.transports.map(transport =>
        <Text key={`job-transport_${transport}`}>
          <CatalogMapping.Name id={transport} />
        </Text>
      )} */}

      {/* <Divider>Notes</Divider>
      <Text>{body.notes}</Text> */}
    </SpacedBox>
  </Panel>

export default WorksheetSummary
