import { FC, useState } from 'react'
import { List, Text, Title, Section, Timestamp } from "../../components"
import { useQuery } from '../../core'
import { Timelog } from './types'
import { Paginator } from '../../shared'
import UserName from '../../shared/user/user-name'

interface TimelogListProps {
}

const TimelogList: FC<TimelogListProps> = () => {
  const [page, setPage] = useState(0)
  const itemsPerPage = 20

  const { collection: documents, total } = useQuery<Timelog>({
    type: "navarik.inspection.timelogResult"
  }, {
    limit: itemsPerPage,
    offset: itemsPerPage * page
  })

  return (
    <Section>
      <List columns={["10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%"]}>
        <List.Header>
          <Title size="sm">Worksheet ID</Title>
          <Title size="sm">Inspector Ref #</Title>
          <Title size="sm">Activity</Title>
          <Title size="sm">Trip Number</Title>
          <Title size="sm">Cargo</Title>
          <Title size="sm">Timelog ID</Title>
          <Title size="sm">Xref</Title>
          <Title size="sm">Status</Title>
          <Title size="sm">Released On</Title>
          <Title size="sm">Released By</Title>
        </List.Header>

        {documents.map(document =>
          <List.Item key={`quantityResult_${document.id}`}>
            <Text>[worksheet.id]</Text>
            <Text>[worksheet.body.inspectorReferenceNumber]</Text>
            <Text>[worksheet.body.activity]</Text>
            <Text>[worksheet.body.tripNumber]</Text>
            <Text>[worksheet.body.cargo.product]</Text>
            <Text>{document.id}</Text>
            <Text>{document.body.xref}</Text>
            <Text>{document.body.status}</Text>
            <Text><Timestamp>{document.modified_at}</Timestamp></Text>
            <UserName id={document.modified_by} />
          </List.Item>
        )}
      </List>
      <Section.Footer>
        <Paginator page={page} totalPages={Math.ceil(total / itemsPerPage)} onPageChange={setPage} />
      </Section.Footer>
    </Section>
  )
}

export default TimelogList
