import { FC, useState } from 'react'
import { List, useOverlay, Button, Text, Title, SpacedBox, IconButton, DebugInfo, Section } from "../../components"
import { useQuery } from '../../core'
import { Job } from './types'
import WorksheetForm from './worksheet/worksheet-form'
import JobStatusIndicator from './job-status-indicator'
import { Paginator } from '../../shared'

interface WorksheetListProps {
  onJobSelection: (jobId: string) => void
}

const WorksheetList: FC<WorksheetListProps> = ({ onJobSelection }) => {
  const { open: openTray, close: closeTray } = useOverlay("tray")
  const [page, setPage] = useState(0)
  const itemsPerPage = 20

  const { collection: documents, total } = useQuery<Job>({
    type: "navarik.inspection.worksheet"
  }, {
    limit: itemsPerPage,
    offset: itemsPerPage * page
  })

  const createJob = () => openTray(
    <WorksheetForm onSuccess={closeTray} onCancel={closeTray} />,
    { size: "large" }
  )

  return (
    <Section>
      <List columns={["30%", "30%", "30%", "11%"]}>
        <List.Header>
          <Title size="sm">TRIP NUMBER</Title>
          <Title size="sm">ACTIVITY</Title>
          <Title size="sm">STATUS</Title>

          <SpacedBox align='end'>
            <Button onClick={createJob} icon="add" title="Create New" />
          </SpacedBox>
        </List.Header>

        {documents.map(document =>
          <List.Item key={`worksheet_${document.id}`} onClick={() => onJobSelection(document.id)}>
            <SpacedBox><Text>{document.body.tripNumber}</Text><DebugInfo>{document.id}</DebugInfo></SpacedBox>
            <Text>{document.body.activity}</Text>
            <JobStatusIndicator status={document.body.status} />

            <SpacedBox align='end'>
              <IconButton onClick={() => console.log("AAAAAAAAA")} icon="delete" title="Delete" />
            </SpacedBox>
          </List.Item>
        )}
      </List>
      <Section.Footer>
        <Paginator page={page} totalPages={Math.ceil(total / itemsPerPage)} onPageChange={setPage} />
      </Section.Footer>
    </Section>
  )
}

export default WorksheetList
