import { Dictionary } from '@navarik/types'
import { FC, useState } from 'react'
import ReactSelect from "react-select"

interface SelectProps {
  onChange: (value: any) => void
  onInput: (value: string) => void
  options: Dictionary<any>
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlighted"|"inverted"|"danger"
  placeholder?: string
  autoFocus?: boolean
  value?: any
}

export const Select: FC<SelectProps> = ({ onChange, onInput, options, placeholder = "", value = "", autoFocus = false }) => {
  const [currentValue, setCurrentValue] = useState(value)

  const compiledOptions: Array<{ value: string, label: string }> = []
  for (const value in options) {
    compiledOptions.push({ value, label: options[value] })
  }

  const currentOption = compiledOptions.find(x => x.value === currentValue)

  return (
    <ReactSelect
      // size={size}
      // color={color}
      options={compiledOptions}
      autoFocus={autoFocus}
      value={currentOption}
      placeholder={placeholder}
      onChange={setCurrentValue}
      onBlur={() => onChange(currentValue)}
      onInputChange={onInput}
    />
  )
}