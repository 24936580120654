import { createAuth0Client , Auth0Client } from '@auth0/auth0-spa-js'
import { createBrowserHistory as history } from 'history'
import { AuthClientAdapter } from '../types'

export class Auth0ClientAdapter implements AuthClientAdapter {
  public isAuthenticated: boolean = false
  private domain: string
  private clientId: string
  private redirectUri: string
  private client: Auth0Client|undefined

  constructor({ domain, clientId, redirectUri }) {
    this.domain = domain
    this.clientId = clientId
    this.redirectUri = redirectUri
  }

  async getClient(): Promise<Auth0Client> {
    if (!this.client) {
      this.client = await createAuth0Client({
        domain: this.domain,
        clientId: this.clientId
      })
    }

    return this.client
  }

  async init() {
    const client = await this.getClient()

    // Code exchange request?
    if (window.location.search.includes('code=')) {
      await client.handleRedirectCallback()
    }

    this.isAuthenticated = await client.isAuthenticated()
  }

  async getIdentityToken() {
    if (!this.isAuthenticated) {
      await this.login()
    }

    const client = await this.getClient()
    let token = await client.getIdTokenClaims()
    if (!token) {
      await this.init()
      if (await client.getTokenSilently()) {
        token = await client.getIdTokenClaims()
      }

      if (!token) {
        return ""
      }
    }

    return token.__raw
  }

  async getUserProfile() {
    const client = await this.getClient()
    const user = await client.getUser()

    if (!user) {
      return {}
    }

    return {
      email: user.email,
      firstName: user.given_name,
      lastName: user.family_name,
      displayName: user.name,
      avatar: user.picture
    }
  }

  async login() {
    const client = await this.getClient()

    try {
      await client.loginWithRedirect({
        authorizationParams: {
          redirect_uri: this.redirectUri
        }
      })

      this.isAuthenticated = false
    } catch (error) {
      // TODO: handle error
      console.error('Login error', error)
    }
  }

  async logout() {
    const client = await this.getClient()
    await client.logout({
      logoutParams: {
        returnTo: this.redirectUri
      }
    })
  }
}
