import { FC } from "react"
import styled from 'styled-components'
import { Icon, useOverlay, Text } from "./components"

const Container = styled.div`
  position: absolute;
  top: 30vh;
  left: 30vw;
  width: 50vw;
  height: 35vh;
  z-index: 40;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  padding: 0;

  background: white;
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props.theme.border["main"]};
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);

  @media (max-width: 480px) {
    width: 90%;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border-bottom: ${({ theme }) => theme.border["main"]};
`

export const Modal: FC<{}> = () => {
  const { isOpen, parameters, close, content } = useOverlay<{ title: string }>("modal")

  if (!isOpen) {
    return null
  }

  const title = parameters.title || ""

  return (
    <Container>
      <Header>
        <Text size="lg">{title}</Text>
        <Icon.X color="#727272" size="22px" onClick={close} />
      </Header>

      <Content>{content}</Content>
    </Container>
  )
}
