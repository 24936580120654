import { FC } from 'react'
import { Tabs } from "../../components"
import QualityResultList from './quality-result-list'
import QuantityResultList from './quantity-result-list'
import TimelogList from './timelog-list'
import WorksheetList from './worksheet-list'

interface InspectionDocumentsListProps {
  onJobSelection: (jobId: string) => void
}

const InspectionDocumentsList: FC<InspectionDocumentsListProps> = ({ onJobSelection }) =>
  <Tabs>
    <Tabs.Pane key="worksheets_tab" name="worksheets" title="Worksheets">
      <WorksheetList onJobSelection={onJobSelection} />
    </Tabs.Pane>
    <Tabs.Pane key="quantity_results_tab" name="quantity_results" title="Quantity">
      <QuantityResultList />
    </Tabs.Pane>
    <Tabs.Pane key="quality_results_tab" name="quality_results" title="Quality">
      <QualityResultList />
    </Tabs.Pane>
    <Tabs.Pane key="timelogs_tab" name="timelogs" title="Timelogs">
      <TimelogList />
    </Tabs.Pane>
  </Tabs>

export default InspectionDocumentsList
