import { FC } from "react"
import { Text } from "../../components"
import { useProfile } from "./use-user-profile"

interface UserNameProps {
  id: string
  size?: "xsm" | "sm" | "m" | "lg" | "xlg"
}

const UserName: FC<UserNameProps> = ({ id, size = "sm" }) => {
  const user = useProfile(id)
  if (!user) {
    return null
  }

  if (user.type === "navarik.id.serviceAccount") {
    return <Text size={size}>{user.body["description"]}</Text>
  }

  return (<>
    {user.body["name"]
      ? <Text size={size}>{user.body["name"]}</Text>
      : <Text size={size}>{user.body["email"]}</Text>}
  </>
  )
}

export default UserName
