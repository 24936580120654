import { FC } from 'react'
import { Text, Button, FieldCluster, Field, SpacedBox, useOverlay, Spinner, Panel, DebugInfo } from "../../../components"
import { useDocument } from '../../../core'
import ClientSecretList from './secret-list'
import ServiceAccountForm from './service-account-form'
import { ServiceAccount } from '../types'

interface ServiceAccountViewProps {
  tenantId: string
  id: string
}

const ServiceAccountView: FC<ServiceAccountViewProps> = ({ tenantId, id }) => {
  const { open: openTray, close: closeTray } = useOverlay("tray")

  const serviceAccount = useDocument<ServiceAccount>(id)
  if (!serviceAccount) {
    return <Spinner />
  }

  const editServiceAccount = () => openTray(
    <ServiceAccountForm {...serviceAccount} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  return (
    <SpacedBox direction="column">
      <Panel>
        <SpacedBox align="spread">
          <SpacedBox>
            <Text size="lg">Service Account "{serviceAccount.body.description}"</Text>
            <DebugInfo>{serviceAccount.id}</DebugInfo>
          </SpacedBox>
          <Button onClick={editServiceAccount} icon="edit" title="Edit" />
        </SpacedBox>

        <FieldCluster>
          <Field title="Description:">
            {serviceAccount.body.description}
          </Field>
          <Field title="OAuth2 Client ID:">{serviceAccount.body.clientId}</Field>
        </FieldCluster>
      </Panel>

      <ClientSecretList tenantId={tenantId} serviceAccountId={id} />
    </SpacedBox>
  )
}

export default ServiceAccountView
