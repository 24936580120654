import { FC, Children, useState } from 'react'
import styled from 'styled-components'
import { Text, Title } from "../index"
import { Pane } from "./pane"
import { Tab } from "./tab"
import { TabSwitch } from "./tab-switch"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${props => props.theme.background.inverted};
`

interface TabsProps {
  active?: string
  onChange?: (newActive: string) => void
}

type TabsType = FC<TabsProps> & {
  Pane: typeof Pane
}

const Tabs: TabsType = ({ children, active, onChange = () => {} }) => {
  const tabs = {}
  Children.forEach(children, (child: any) => {
    tabs[child.props.name] = child
  })

  const currentlyActive = active || Object.keys(tabs)[0]
  const [activeTab, setActiveTab] = useState(currentlyActive)

  const changeActiveTab = (newActive: string) => {
    onChange(newActive)
    setActiveTab(newActive)
  }

  return (
    <Container>
      <TabSwitch>
      {Children.toArray(children).map((child: any) =>
        <Tab
          key={`tab_name_${child.props.name}`}
          active={child.props.name === activeTab}
          onClick={() => changeActiveTab(child.props.name)}
        >
          {child.props.name === activeTab
            ? <Title size="sm">{child.props.title}</Title>
            : <Text>{child.props.title}</Text>
          }
        </Tab>
      )}
      </TabSwitch>

      {tabs[activeTab]}
    </Container>
  )
}

Tabs.Pane = Pane

export default Tabs
