import { FC, useState } from 'react'
import { List, useOverlay, Button, Title, IconButton, SpacedBox, Section, Text, DebugInfo } from "../../../components"
import { useCore, useQuery } from '../../../core'
import { TenantMembership } from '../types'
import { ConfirmationModal, Paginator } from '../../../shared'
import User from '../../../shared/user'
import MembershipStatus from './membership-status'
import InvitationForm from './invitation-form'
import MembershipForm from './membership-form'

interface UserListProps {
  tenantId: string
}

const UserList: FC<UserListProps> = ({ tenantId }) => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useOverlay("tray")
  const { open: openModal, close: closeModal } = useOverlay("modal")
  const [page, setPage] = useState(0)
  const itemsPerPage = 20

  const { collection: memberships, total } = useQuery<TenantMembership>({
    type: "navarik.id.tenant.membership",
    "body.tenant": tenantId
  }, {
    limit: itemsPerPage,
    offset: itemsPerPage * page
  })

  const inviteUser = () => openTray(<InvitationForm tenantId={tenantId} onSuccess={closeTray} />)
  const editUser = (id: string, body: TenantMembership) => openTray(
    <MembershipForm id={id} body={body} onClose={closeTray} />
  )

  const getUserProfile = async (userId) => {
    const user = await core.command("navarik.id.whoIs", { id: userId })
    return user.body
  }

  const disableUser = async (id) => {
    const userProfile = await getUserProfile(id)
    openModal(
      <ConfirmationModal
        text={`Are you sure you want to disable user ${userProfile.name === "Invited" ? '' : userProfile.name} (${userProfile.email})?`}
        onConfirm={() => core.command("navarik.id.tenant.membership.disable", { tenant: tenantId, id })}
        onCancel={closeModal}
        onDone={closeModal}
      />,
      { title: "User action confirmation." }
    )
  }

  const enableUser = async (id) => {
    const userProfile = await getUserProfile(id)
    openModal(
      <ConfirmationModal
        text={`Are you sure you want to enable user ${userProfile.name === "Invited" ? '' : userProfile.name} (${userProfile.email})?`}
        onConfirm={() => core.command("navarik.id.tenant.membership.enable", { tenant: tenantId, id })}
        onCancel={closeModal}
        onDone={closeModal}
      />,
      { title: "User action confirmation." }
    )
  }

  return (
    <Section>
      <List columns={["35%", "25%", "20%", "10%", "11%"]}>
        <List.Header>
          <Title size="sm">NAME</Title>
          <Title size="sm">EMAIL</Title>
          <Title size="sm">ROLE</Title>
          <Title size="sm">STATUS</Title>

          <SpacedBox align='end'>
            <Button onClick={inviteUser} icon="add" title="Add User" />
          </SpacedBox>
        </List.Header>

        {memberships.map(membership =>
          <List.Item key={`tenant_user_${membership.id}`} >
            <SpacedBox><User.Name id={membership.id}/><DebugInfo>{membership.id}</DebugInfo></SpacedBox>
            <User.Email id={membership.id} />
            <Text>{membership.body.role}</Text>
            <MembershipStatus status={membership.body.status} />

            <SpacedBox align='end'>
              {membership.body.status === "disabled" &&
                <IconButton onComplete={() => enableUser(membership.id)} icon="enable" title="Enable" />
              }
              {membership.body.status !== "disabled" &&
              <>
                <IconButton onComplete={() => editUser(membership.id, membership.body)} icon="edit" title="Change role" />
                <IconButton onComplete={() => disableUser(membership.id)} icon="disable" title="Disable" />
              </>
              }
            </SpacedBox>
          </List.Item>
        )}
      </List>

      <Section.Footer>
        <Paginator page={page} totalPages={Math.ceil(total/itemsPerPage)} onPageChange={setPage} />
      </Section.Footer>
    </Section>
  )
}

export default UserList
