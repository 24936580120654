import { FC, useContext } from "react"
import { DevModeContext } from './dev-mode-context'
import { Text } from '../../components'

interface DebugInfoProps{
  children: string | null
}

export const DebugInfo:FC<DebugInfoProps> = ({ children }) => {
  const { showContent } = useContext(DevModeContext)
  return (
    (
      showContent && children &&
      <Text size="xsm" style={{ fontStyle: "italic", fontWeight: "bold" }}>
        ({children})
      </Text>
    )
  )
}