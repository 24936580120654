import { Dictionary } from '@navarik/types'
import { createContext, useContext, useState, FC } from 'react'
import { useKeydownHandler } from '..'
import { Overlay } from "./overlay"
import Backdrop from './backdrop'

interface OverlayContextInterface {
  overlays: Dictionary<Overlay>
  open: (name: string, overlay: Overlay) => void
  close: (name: string) => void
  closeAll: () => void
}

const OverlayContext = createContext<OverlayContextInterface>({
  overlays: {},
  open: () => {},
  close: () => {},
  closeAll: () => {}
})

interface OverlayProviderProps {
  names: Array<string>
}

interface StateContent {
  overlays: Dictionary<Overlay>
  stack: Array<string>
}

export const OverlayProvider: FC<OverlayProviderProps> = ({ children, names }) => {
  const overlays = names.reduce((acc, name) => ({ ...acc, [name]: new Overlay(null, {}) }), {})
  const [state, setState] = useState<StateContent>({ overlays, stack: [] })

  useKeydownHandler((key) => {
    if (key === "Escape" && state.stack[0]) {
      close(state.stack[0])
    }
  })

  const open = (name: string, overlay: Overlay) => {
    setState({
      overlays: {
        ...state.overlays,
        [name]: { ...overlay, isOpen: true }
      },
      stack: [name, ...state.stack]
    })
  }

  const close = (name: string) => {
    const current = state.overlays[name]
    if (!current) {
      return
    }

    setState({
      overlays: {
        ...state.overlays,
        [name]: { ...current, isOpen: false }
      },
      stack: state.stack.filter(x => x !== name)
    })

    current.onClose()
  }

  const closeAll = () => {
    const newState = {}
    for (const name in state.overlays) {
      newState[name] = {
        ...state.overlays[name],
        isOpen: false
      }
    }

    setState({
      overlays: newState,
      stack: []
    })
  }

  return (
    <OverlayContext.Provider value={{ overlays: state.overlays, open, close, closeAll }}>
      {state.stack[0] && <Backdrop onClick={() => close(state.stack[0])}/>}
      <>{children}</>
    </OverlayContext.Provider>
  )
}

export function useOverlay<T extends object>(name: string) {
  const context = useContext(OverlayContext)
  if (!context) {
    throw new Error(`useOverlay must be used within a OverlayProvider.`)
  }

  const overlay = context.overlays[name] as Overlay<T>
  if (!overlay) {
    throw new Error(`Unknown overlay: ${name}.`)
  }

  return {
    ...overlay,
    open: (content, parameters?: T, onClose?: () => void) =>
      context.open(name, new Overlay<T>(content, parameters, onClose)),
    close: () => context.close(name),
    closeAll: () => context.closeAll()
  }
}
