import styled from 'styled-components'

export const Toolbar = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: ${props => props.theme.background.inverted};
`
