import styled from 'styled-components'

const Sidebar = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  overflow: hidden;
  background-color: ${props => props.theme.background.inverted};
  border-right: ${({ theme }) => theme.border.main};
`

export default Sidebar
